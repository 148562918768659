import { Icon, IconButton, useColorMode, VStack } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LuChevronsDown, LuChevronsUp } from "react-icons/lu";

function getBackgroundShadows(
  isBottom: boolean,
  isTop: boolean,
  isBetween: boolean,
  colorMode: "dark" | "light"
) {
  const shadowColor =
    colorMode === "dark"
      ? "var(--chakra-colors-gray-800)"
      : "var(--chakra-colors-gray-200)";
  const topCover = `linear-gradient(${shadowColor} 20%, transparent) center top`;
  const topShadow = `radial-gradient(farthest-side at 50% 0, ${shadowColor}, rgba(0, 0, 0, 0)) center top`;
  const bottomCover = `linear-gradient(transparent, ${shadowColor} 80%) center bottom`;
  const bottomShadow = `radial-gradient(farthest-side at 50% 100%, ${shadowColor}, rgba(0, 0, 0, 0)) center bottom`;

  const backgroundRepeat = "no-repeat";
  const backgroundSize = "100% 2.5rem, 100% 2.5rem, 100% 2rem, 100% 2rem";
  const backgroundAttachment = "local, local, scroll, scroll";

  if (isTop && !isBottom) {
    return {
      background: `${bottomCover}, ${bottomShadow};`,
      backgroundRepeat,
      backgroundSize,
      backgroundAttachment,
    };
  } else if (isBetween) {
    return {
      background: `${topCover}, ${bottomCover}, ${topShadow}, ${bottomShadow};`,
      backgroundRepeat,
      backgroundSize,
      backgroundAttachment,
    };
  } else if (isBottom && !isTop) {
    return {
      background: `${topCover}, ${topShadow};`,
      backgroundRepeat,
      backgroundSize,
      backgroundAttachment,
    };
  }

  return {};
}

const findScrollableElement = (
  container: HTMLElement | null
): HTMLElement | null => {
  if (!container) return null;

  const isContainerScrollable = container.scrollHeight > container.clientHeight;

  if (isContainerScrollable) {
    return container;
  }

  // Find the first sibling that is scrollable
  const scrollableElement = Array.from(container.children).find((child) => {
    const element = child as HTMLElement;
    return element.scrollHeight > element.clientHeight;
  });

  return (scrollableElement as HTMLElement) || null;
};

export function useScrollWithShadow<T extends HTMLElement>() {
  const { colorMode } = useColorMode();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [clientHeight, setClientHeight] = useState<number>(0);

  const [isTop, setIsTop] = useState<boolean>(true);
  const [isBottom, setIsBottom] = useState<boolean>(true);
  const [isBetween, setIsBetween] = useState<boolean>(false);

  const scrollHelperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
      setIsTop(true);
      setIsBottom(true);
      setIsBetween(false);
    };
  }, []);

  useEffect(() => {
    const newIsBottom = clientHeight === scrollHeight - scrollTop;
    const newIsTop = scrollTop === 0;
    const newIsBetween =
      scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

    setIsTop(newIsTop);
    setIsBottom(newIsBottom);
    setIsBetween(newIsBetween);
  }, [scrollTop, scrollHeight, clientHeight]);

  const onScrollHandler = useCallback((event: React.UIEvent<T>) => {
    if (event.target instanceof HTMLElement) {
      setScrollTop(event.target.scrollTop);
      setScrollHeight(event.target.scrollHeight);
      setClientHeight(event.target.clientHeight);
    }
  }, []);

  const topArrow = useMemo(() => {
    const scrollToStart = () => {
      if (scrollHelperRef.current === null) {
        return;
      }

      const container = scrollHelperRef.current.parentElement;

      if (container === null) {
        return;
      }

      const scrollableElement = findScrollableElement(container);

      if (scrollableElement === null) {
        return;
      }

      scrollableElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <IconButton
        aria-label="Scroll up a little bit"
        colorScheme={colorMode === "light" ? "blackAlpha" : "white"}
        zIndex={9999}
        pointerEvents="auto"
        isRound={true}
        onClick={scrollToStart}
      >
        <Icon as={LuChevronsUp} />
      </IconButton>
    );
  }, []);

  const bottomArrow = useMemo(() => {
    const scrollToBottom = () => {
      if (scrollHelperRef.current === null) {
        return;
      }

      const container = scrollHelperRef.current.parentElement;

      if (container === null) {
        return;
      }

      const scrollableElement = findScrollableElement(container);

      if (scrollableElement === null) {
        return;
      }

      scrollableElement.scrollTo({
        top: scrollableElement.scrollHeight,
        behavior: "smooth",
      });
    };

    return (
      <IconButton
        pointerEvents="auto"
        aria-label="Scroll down a little bit"
        colorScheme={colorMode === "light" ? "blackAlpha" : "white"}
        zIndex={9999}
        isRound={true}
        onClick={scrollToBottom}
      >
        <Icon as={LuChevronsDown} />
      </IconButton>
    );
  }, []);

  const scrollHelper = useMemo(() => {
    return (
      <VStack
        ref={scrollHelperRef}
        position="absolute"
        right={0}
        top={0}
        bottom={0}
        left={0}
        zIndex={1}
        py={4}
        pointerEvents="none"
        width="100%"
        height="100%"
        {...getBackgroundShadows(isBottom, isTop, isBetween, colorMode)}
        minH="100%"
        minW="100%"
        justifyContent={
          isTop ? "flex-end" : isBottom ? "flex-start" : "space-between"
        }
      >
        {(isBottom && !isTop) || isBetween ? topArrow : null}
        {(isTop && !isBottom) || isBetween ? bottomArrow : null}
      </VStack>
    );
  }, [isBottom, isTop, isBetween, colorMode]);

  return { scrollHelper, onScrollHandler };
}
