import {
  Button,
  ListItem,
  MenuItem,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

interface CustomFieldMenuItemProps {
  onClick: () => void;
  customFieldKey: string;
}

export const humanizeCustomFieldKey = (key: string) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const CustomFieldMenuItem: React.FC<CustomFieldMenuItemProps> = ({
  onClick,
  customFieldKey,
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [isOverflown, setIsOverflown] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const button = ref.current;
    setIsOverflown(button.scrollWidth > button.clientWidth);
  }, [ref.current]);

  return (
    <Tooltip
      label={humanizeCustomFieldKey(customFieldKey)}
      isDisabled={!isOverflown || isBaseSize}
      placement="right"
    >
      <ListItem
        as={Button}
        ref={ref}
        mt={2}
        px={2}
        py={1}
        onClick={onClick}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        w="100%"
        display="block"
        textAlign="left"
        verticalAlign="middle"
        colorScheme="gray"
        borderRadius={0}
        variant="ghost"
        fontWeight="normal"
      >
        {humanizeCustomFieldKey(customFieldKey)}
      </ListItem>
    </Tooltip>
  );
};

export default CustomFieldMenuItem;
