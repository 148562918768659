import React from "react";
import FormGroup from "../form-group";
import BroadcastAppUpdatedForm from "../forms/BroadcastAppUpdatedForm";

const Broadcast = () => {
  return (
    <>
      <FormGroup title="Broadcast">
        <BroadcastAppUpdatedForm />
      </FormGroup>
    </>
  );
};

export default Broadcast;
