import { Box, useBreakpointValue } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TemplateDomain from "entities/domain/templates";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import { RenderAware, RenderProvider } from "hooks/use-render-context";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  setMessageInputIsPreparing,
  setMessageInputTemplate,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ActionButtons from "./ActionButtons";
import Attachments from "./Attachments";
import SubjectPreview from "./SubjectPreview";

interface MessagePreviewProps {
  onScrollHandler?: (e: React.UIEvent<HTMLDivElement>) => void;
}

const MessagePreview: React.FC<MessagePreviewProps> = ({ onScrollHandler }) => {
  const dispatch = useAppDispatch();
  const {
    activeConversation,
    messageInput: { template, text, subject, attachments },
  } = useAppSelector((state) => state.conversations);

  const availableCustomFields = useAvailableCustomFields(template);

  const editorText = useMemo(() => {
    return { value: text };
  }, [
    text,
    availableCustomFields, // This is a dependency because the editor text should be updated when the custom fields are updated
  ]);

  const channels = useMemo(
    () => (activeConversation ? [activeConversation.channel] : undefined),
    [activeConversation]
  );
  const enabledPlugins = useMemo(() => {
    if (channels && channels.includes(ConversationChannel.WHATSAPP)) {
      return [EditorUltraPlugin.RICHTEXT];
    }

    return [];
  }, [channels]);

  const addOrReplaceCustomField = useCallback(
    (key: string, value: string) => {
      if (!template) {
        return;
      }

      dispatch(
        setMessageInputTemplate(
          Object.setPrototypeOf(
            {
              ...template,
              id: template.id,
              customFields: {
                ...template.customFields,
                [key]: value,
              },
            },
            TemplateDomain.prototype
          )
        )
      );
    },
    [template?.customFields]
  );

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const waitFor = useMemo(() => {
    const subjectNeedsPainting = !!subject;
    const textNeedsPainting = !!editorText.value;
    const attachmentsNeedPainting = !!attachments.length;
    const buttonsNeedPainting = !!((template && template.buttons) || []).length;

    const newWaitFor = [];

    if (subjectNeedsPainting) {
      newWaitFor.push("subject");
    }

    if (textNeedsPainting) {
      newWaitFor.push("text");
    }

    if (attachmentsNeedPainting) {
      newWaitFor.push("attachments");
    }

    if (buttonsNeedPainting) {
      newWaitFor.push("buttons");
    }

    return newWaitFor;
  }, [subject, text, template, attachments]);

  const resetRef = useRef<() => void>(() => {});

  const [shouldShow, setShouldShow] = useState<boolean>(false);

  useEffect(() => {
    if (resetRef.current) {
      resetRef.current();
    }
    setShouldShow(false);
    const timer = setTimeout(() => {
      setShouldShow(true);
    }, 0);

    return () => {
      clearTimeout(timer);
      setShouldShow(true);
      dispatch(setMessageInputIsPreparing(false));
    };
  }, [template?.id]);

  return !shouldShow ? (
    <Box w="100%" h="100%" minH="5rem" />
  ) : (
    <Box
      w="100%"
      h="100%"
      minH="5rem"
      ref={(ref) => {
        if (onScrollHandler) {
          onScrollHandler({
            target: ref,
          } as any);
        }
      }}
      overflowY="auto"
      data-scroll-lock-scrollable
      onScroll={isBaseSize ? onScrollHandler : undefined}
      sx={{
        "#editor-ultra": {
          p: 2,
        },
      }}
    >
      <RenderProvider
        waitFor={waitFor}
        resetRef={resetRef}
        onComplete={() => {
          dispatch(setMessageInputIsPreparing(false));
        }}
      >
        <RenderAware id="subject" shouldTrack={!!subject} debounce={0}>
          <SubjectPreview />
        </RenderAware>
        <RenderAware
          id="attachments"
          shouldTrack={!!attachments.length}
          debounce={0}
        >
          <Attachments size="md" />
        </RenderAware>
        <RenderAware id="text" shouldTrack={!!editorText.value} debounce={250}>
          <EditorUltra
            isPrivate={true}
            key={activeConversation?.id}
            defaultText={editorText}
            isEditable={false}
            isScrollable={false}
            placeholder=""
            isDisabled={false}
            customFields={template ? availableCustomFields : null}
            addOrReplaceCustomField={addOrReplaceCustomField}
            enabledPlugins={enabledPlugins}
            channels={channels}
          />
        </RenderAware>
        <RenderAware
          id="buttons"
          shouldTrack={!!((template && template.buttons) || []).length}
          debounce={0}
        >
          <ActionButtons />
        </RenderAware>
      </RenderProvider>
    </Box>
  );
};

export default MessagePreview;
