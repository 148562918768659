import { Auth0ContextInterface } from "@auth0/auth0-react";
import { request } from "util/methods";
import { RequestType } from "./request-type";

class CustomFieldService {
  public static async getCustomFieldsForGroup(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string
  ): Promise<string[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchant-groups/${groupId}/custom-fields`
    );

    // In the backend we support these fields as well but it is confusing to have them shown on the UI along with contact_first_name and contact_last_name, so we filter them out here
    // Backend might migrate these fields to contact_first_name and contact_last_name in the future
    const customFields: string[] = (data.data as string[]).filter(
      (f) =>
        !["customer_name", "customer_first_name", "customer_surname"].includes(
          f
        )
    );

    return customFields;
  }
}

export default CustomFieldService;
