import { Link, Text } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import AgentDomain from "entities/domain/agents/agent-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import DownloadExpiredIconButton from "./DownloadExpiredButton";
import ExpandableText from "./ExpandableText";
import MessageWrapper from "./MessageWrapper";

const extractFileNameFromUrl = (url: string) => {
  const fileName = url.split("-").pop();

  return fileName;
};

export default ({
  message,
  showTail,
}: {
  message: MessageDomain;
  showTail: boolean;
}) => {
  const { agents } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [agent, setAgent] = useState<AgentDomain | undefined>(
    agents.find((a: AgentDomain) => a.id === message.agentId)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [brokenMediaType, setBrokenMediaType] = useState<
    AttachmentType | undefined
  >(undefined);

  useEffect(() => {
    setAgent(agents.find((a: AgentDomain) => a.id === message.agentId));
  }, [agents]);

  return (
    <MessageWrapper
      message={message}
      showTail={showTail}
      activeConversation={activeConversation}
      agent={agent}
    >
      <>
        {activeConversation?.isChannelFacebookOrInstagram() &&
        brokenMediaType === AttachmentType.FILE ? (
          <DownloadExpiredIconButton
            message={message}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setBrokenMediaType={setBrokenMediaType}
          />
        ) : (
          <>
            {activeConversation?.channel === ConversationChannel.WHATSAPP ? (
              <EditorUltra
                isPrivate={true}
                fontColor={message.getFontColor()}
                isEditable={false}
                isScrollable={false}
                maxHeight="150px"
                showMore={true}
                backgroundColor="inherit"
                highlightUnknownCustomFields={false}
                defaultText={{
                  value: message?.body,
                }}
                channels={[ConversationChannel.WHATSAPP]}
                enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
              />
            ) : (
              <ExpandableText
                message={message}
                shouldLinkify={message.hasURLs()}
              />
            )}
            <Link
              textDecorationColor={`${colorScheme}.400`}
              textColor={`${colorScheme}.400`}
              href={message.attachments[0].url}
              data-private={true}
              download
              target="_blank"
            >
              <Text>
                {extractFileNameFromUrl(message.attachments[0].url) ||
                  "Open attachment"}
              </Text>
            </Link>
          </>
        )}
      </>
    </MessageWrapper>
  );
};
