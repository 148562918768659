import { extendTheme } from "@chakra-ui/react";
import { StyleConfig } from "@chakra-ui/theme-tools";
import ButtonStyles from "./components/Button";
import HeadingStyles from "./components/Heading";
import InputStyles from "./components/Input";
import LinkStyles from "./components/Link";
import ModalStyles from "./components/Modal";
import NumberInputStyles from "./components/NumberInput";
import PopoverStyles from "./components/Popover";
import TabsStyles from "./components/Tabs";
import ToolTipStyles from "./components/ToolTip";

const config = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const components: Record<string, StyleConfig> = {
  Link: LinkStyles,
  Input: InputStyles,
  Button: ButtonStyles,
  Heading: HeadingStyles,
  Tabs: TabsStyles,
  Modal: ModalStyles,
  Tooltip: ToolTipStyles,
  Popover: PopoverStyles,
  NumberInput: NumberInputStyles,
};

// Used this to generate palettes https://palette.saas-ui.dev/
const FuzeyTheme = extendTheme({
  config,
  colors: {
    blue: {
      "50": "#f5faff",
      "100": "#e6f3ff",
      "200": "#d4eaff",
      "300": "#afd8ff",
      "400": "#60b2ff",
      "500": "#007df2",
      "600": "#0054a2",
      "700": "#00376a",
      "800": "#00213f",
      "900": "#0a1546",
    },
    gray: {
      50: "#F1F1F4",
      100: "#D7D8DF",
      200: "#BEBFCB",
      300: "#A5A6B6",
      400: "#8B8DA2",
      500: "#72748D",
      600: "#5B5D71",
      700: "#444655",
      800: "#2D2F39",
      900: "#17171C",
    },
    green: {
      50: "#EDF7F3",
      100: "#CDEADE",
      200: "#ACDCC8",
      300: "#8CCFB3",
      400: "#6CC19E",
      500: "#4BB488",
      600: "#3C906D",
      700: "#2D6C52",
      800: "#1E4837",
      900: "#0F241B",
    },
    purple: {
      50: "#f8f6ff",
      100: "#e5d9fd",
      200: "#d1bdfc",
      300: "#b494fa",
      400: "#a076f8",
      500: "#834df6",
      600: "#6b2bf4",
      700: "#4b00eb",
      800: "#3e00c4",
      900: "#2f0095",
    },
    pink: {
      50: "#fef5fa",
      100: "#fdd6ea",
      200: "#fbb5d9",
      300: "#f983c0",
      400: "#f75aab",
      500: "#ee007b",
      600: "#cd006a",
      700: "#a90057",
      800: "#850045",
      900: "#640034",
    },
    orange: {
      50: "#fefaf5",
      100: "#fdead8",
      200: "#fbd1aa",
      300: "#f7ac65",
      400: "#f4861e",
      500: "#de6b00",
      600: "#bb5a00",
      700: "#954800",
      800: "#763900",
      900: "#612f00",
    },
    yellow: {
      50: "#fffefb",
      100: "#fef9e5",
      200: "#fbedb0",
      300: "#f8df6f",
      400: "#f2c807",
      500: "#c9a400",
      600: "#a08300",
      700: "#7d6600",
      800: "#5e4d00",
      900: "#4d3f00",
    },
    teal: {
      50: "#e9fefe",
      100: "#91f9f8",
      200: "#00ede9",
      300: "#00d3d0",
      400: "#00b4b1",
      500: "#009996",
      600: "#007c7a",
      700: "#00615f",
      800: "#00514f",
      900: "#004341",
    },
    cyan: {
      50: "#f0fcfe",
      100: "#bdf2fc",
      200: "#9cecfa",
      300: "#74e4f8",
      400: "#00c5e8",
      500: "#00b5d5",
      600: "#00a3c0",
      700: "#00879f",
      800: "#006f83",
      900: "#005665",
    },
  },
  fonts: {
    heading: "Lato, sans-serif",
    body: "Lato, sans-serif",
  },
  components,
  styles: {
    global: {
      outline: "none",
      ":focus:not(:focus-visible):not([role='dialog']):not([role='menu'])": {
        boxShadow: "none !important",
      },
      _focus: {
        boxShadow: "none !important",
      },
    },
  },
});

export default FuzeyTheme;
