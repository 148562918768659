import {
  Text,
  Flex,
  Heading,
  Badge,
  Alert,
  AlertIcon,
  Link,
  Icon,
  useColorMode,
  Box,
  FormControl,
  FormLabel,
  useBreakpointValue,
  Card,
  Stack,
  CardBody,
  StackDivider,
  Progress,
  Tooltip,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { ReactComponent as InformationIcon } from "assets/icons/information.svg";
import AudienceDomain from "entities/domain/audience";
import { CampaignStatus, MessagesAnalytics } from "entities/domain/campaign";
import ReactDatePicker from "react-datepicker";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import React, { useEffect, useState } from "react";
import AudiencesService from "services/audiences";
import CampaignsService from "services/campaigns";
import { useAuth0 } from "@auth0/auth0-react";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import AudienceCustomersList from "components/campaigns/form/steps/audience-customers-list/AudienceCustomersList";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { SegmentedMessage } from "sms-segments-calculator";
import useTemplatesStore from "hooks/use-templates-store";
import { AppleIPhone } from "@kazimirchuk/react-device-preview";
import { removePrefixAndSuffix, replaceCustomFields } from "util/methods";
import { propagateAudienceUpdate } from "redux/features/audiences";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import QueryBuilder from "components/shared/QueryBuilder";
import { GroupConfig } from "components/shared/QueryBuilder/QueryCondition";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import WhatsappPreview from "components/user-settings/TemplateSettings/create-whatsapp-template/Preview";
import FileDomain from "entities/domain/file";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import { customerGroup, vehicleGroup } from "./audience/QueryFields";

const getProgressColorScheme = (
  percentage: number,
  isSoft: boolean = false,
  isReverse: boolean = false
): string => {
  let actualValue = percentage;

  if (isReverse) {
    actualValue = 100 - actualValue;
  }

  if (actualValue < (isSoft ? 5 : 20)) {
    return "red";
  } else if (actualValue < (isSoft ? 10 : 40)) {
    return "orange";
  } else if (actualValue < (isSoft ? 15 : 60)) {
    return "yellow";
  } else {
    return "green";
  }
};

interface Action {
  text: string;
  requiresConfirmation: boolean;
  setShowConfirmation?: (show: boolean) => void;
  isConfirmed?: boolean;
  action: () => Promise<void>;
}

interface PreviewProps {
  campaignId: string | undefined;
  campaignChannel: ConversationChannel;
  campaignMessage: string | null;
  campaignName: string;
  campaignMediaUrl: string | null;
  campaignMediaType: string | null;
  campaignTemplateName: string | null;
  campaignAudienceId: string | null;
  campaignScheduledFor: string | null;
  campaignStatus: CampaignStatus;
  campaignAnalytics: MessagesAnalytics | null;
  attachedFile: FileDomain | null;
  chosenAction: Action["text"];
  setScheduledFor: (sf: string) => void;
}

const Preview = ({
  campaignId,
  campaignAnalytics,
  campaignName,
  campaignMessage,
  campaignChannel,
  campaignMediaUrl,
  campaignMediaType,
  campaignAudienceId,
  campaignScheduledFor,
  campaignTemplateName,
  campaignStatus,
  attachedFile,
  setScheduledFor,
  chosenAction,
}: PreviewProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { fetchTemplates } = useTemplatesStore();
  const { templates } = useAppSelector((state) => state.templates);
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const auth0Context = useAuth0();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { audiences } = useAppSelector((state) => state.audiences);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [segmentsCount, setSegmentsCount] = useState<number>(0);

  const [chosenAudience, setChosenAudience] = useState<AudienceDomain | null>(
    null
  );
  const [campaignChannels, setCampaignChannels] = useState<
    ConversationChannel[]
  >([campaignChannel]);
  const [campaignSendingStrategy, setCampaignSendingStrategy] =
    useState<null>(null);
  const [campaignAdditionalCost, setCampaignAdditionalCost] =
    useState<null>(null);
  const [audienceCustomersList, setAudienceCustomersList] =
    useState<ContactListDomain>(new ContactListDomain([], 0));
  const [campaignCustomersList, setCampaignCustomersList] =
    useState<ContactListDomain>(new ContactListDomain([], 0));

  const [enabledGroups, setEnabledGroups] = useState<GroupConfig[]>([
    customerGroup,
  ]);

  useEffect(() => {
    if (!!campaignId && campaignStatus === CampaignStatus.DONE) {
      CampaignsService.getCampaignCustomers(
        auth0Context,
        { id: campaignId },
        merchant.id
      ).then(
        (fetchedCampaignCustomersList) => {
          setCampaignCustomersList(fetchedCampaignCustomersList);
        },
        (error) => {
          // eslint-disable-next-line
          console.error("Failed to fetch campaign customers:", error);
        }
      );
    }
  }, [campaignStatus]);

  useEffect(() => {
    if (merchant.isIntegrationEnabled(IntegrationName.KEYLOOP)) {
      setEnabledGroups([customerGroup, vehicleGroup]);
    } else {
      setEnabledGroups([customerGroup]);
    }
  }, [merchant]);

  useEffect(() => {
    setCampaignChannels([campaignChannel]);
  }, [campaignChannel]);

  useEffect(() => {
    fetchTemplates(undefined, campaignChannel !== ConversationChannel.WHATSAPP);
  }, [campaignChannel]);

  useEffect(() => {
    if (!campaignAudienceId || !audiences.length) {
      setChosenAudience(null);
    }

    const foundAudience = audiences.find(
      (a: AudienceDomain) => a.id === campaignAudienceId
    );

    if (foundAudience) {
      setChosenAudience(foundAudience);
    } else {
      setChosenAudience(null);
    }
  }, [audiences, campaignAudienceId]);

  const [previewText, setPreviewText] = useState<string>("");

  useEffect(() => {
    if (campaignChannel !== ConversationChannel.SMS) {
      setSegmentsCount(0);
      return;
    }

    if (!previewText) {
      setSegmentsCount(0);
      return;
    }

    const segmentedMessage = new SegmentedMessage(previewText, "auto", true);

    setSegmentsCount(segmentedMessage.segmentsCount);
  }, [previewText, campaignChannel]);

  useEffect(() => {
    if (!chosenAudience || !campaignAudienceId) {
      return;
    }

    AudiencesService.fetchAudienceCustomers(auth0Context, merchant.id, {
      id: campaignAudienceId,
      channels: [campaignChannel],
    }).then(
      (fetchedAudienceCustomersList) => {
        setAudienceCustomersList(fetchedAudienceCustomersList);
        dispatch(
          propagateAudienceUpdate(
            Object.setPrototypeOf(
              {
                ...chosenAudience,
                count: fetchedAudienceCustomersList.totalCount,
              },
              AudienceDomain.prototype
            )
          )
        );
      },
      (error) => {
        // eslint-disable-next-line
        console.error("Failed to fetch audience customers:", error);
      }
    );
  }, [campaignAudienceId, chosenAudience?.id]);

  const availableCustomFields = useAvailableCustomFields();

  const [templateAttachmentUrl, setTemplateAttachmentUrl] = useState<
    string | null
  >(null);
  const [templateAttachmentType, setTemplateAttachmentType] = useState<
    string | null
  >(null);

  const optionalSuffix = `\nReply UNSUB to unsubscribe`;

  useEffect(() => {
    if (campaignTemplateName) {
      const template = templates.find((t) => t.name === campaignTemplateName);

      if (
        campaignChannel === ConversationChannel.SMS &&
        merchant.country !== "US"
      ) {
        let endText =
          replaceCustomFields(
            template?.getWholeText() || "",
            template?.customFields || availableCustomFields
          ) || "";

        if (template?.getMedia()) {
          endText = `${endText}\nhttp://link.to/your/file`;
        }

        setPreviewText(endText);
        setTemplateAttachmentType(null);
        setTemplateAttachmentUrl(null);
      } else {
        setPreviewText(
          replaceCustomFields(
            template?.getWholeText() || "",
            template?.customFields || availableCustomFields
          ) || ""
        );
        const media = template?.getMedia();
        setTemplateAttachmentType(media?.type || null);
        setTemplateAttachmentUrl(media?.url || null);
      }
    } else {
      const messageBodyWithoutPrefixAndSuffix = campaignMessage
        ? removePrefixAndSuffix(
            campaignMessage,
            `${campaignMessage.split(":")[0]}:`,
            optionalSuffix
          )
        : "";
      const hasMessageBody = replaceCustomFields(
        messageBodyWithoutPrefixAndSuffix,
        availableCustomFields
      );
      let endText =
        replaceCustomFields(campaignMessage || "", availableCustomFields) || "";

      if (
        campaignChannel === ConversationChannel.SMS &&
        merchant.country !== "US" &&
        ((campaignMediaType && campaignMediaUrl) || attachedFile)
      ) {
        endText = `${endText}\nhttp://link.to/your/file`;
      }

      setPreviewText(hasMessageBody ? endText : "");
      setTemplateAttachmentType(null);
      setTemplateAttachmentUrl(null);
    }
  }, [campaignTemplateName, templates, campaignMessage, attachedFile]);

  const [currentTemplate, setCurrentTemplate] =
    useState<RegularOrWhatsappTemplate | null>(null);

  useEffect(() => {
    if (campaignTemplateName) {
      const template = templates.find((t) => t.name === campaignTemplateName);
      setCurrentTemplate(template || null);
    } else {
      setCurrentTemplate(null);
    }
  }, [campaignTemplateName]);

  return (
    <Box height="100%" width="100%" overflowY="auto" pb="5rem">
      <Card
        direction={isBaseSize ? "column" : "row"}
        overflow="hidden"
        variant="outline"
        size={isBaseSize ? "md" : "lg"}
        {...(isBaseSize
          ? {
              alignItems: "center",
              pt: 6,
            }
          : {
              p: "2rem",
            })}
        border="none"
        justifyContent={
          campaignStatus === CampaignStatus.DONE ? "space-evenly" : "center"
        }
      >
        {campaignChannel === ConversationChannel.WHATSAPP ? (
          <Box>
            <WhatsappPreview
              customFields={
                currentTemplate?.customFields || availableCustomFields
              }
              headerText={
                (currentTemplate &&
                  currentTemplate.isWhatsappTemplate() &&
                  currentTemplate.getHeaderText()) ||
                ""
              }
              bodyText={
                (currentTemplate &&
                  currentTemplate.isWhatsappTemplate() &&
                  currentTemplate.getBodyText()) ||
                ""
              }
              footerText={
                (currentTemplate &&
                  currentTemplate.isWhatsappTemplate() &&
                  currentTemplate.getFooterText()) ||
                ""
              }
              buttons={
                (currentTemplate &&
                  currentTemplate.isWhatsappTemplate() &&
                  currentTemplate.getButtons()) ||
                []
              }
              file={null}
              mediaUrl={templateAttachmentUrl || undefined}
              mediaType={templateAttachmentType || undefined}
            />
          </Box>
        ) : (
          <AppleIPhone
            colorMode={colorMode}
            size="sm"
            app={{
              name: "BuiltInMessenger",
              options: {
                messages: [
                  {
                    text: previewText,
                    mediaUrl:
                      campaignChannel === ConversationChannel.SMS &&
                      merchant.country === "US"
                        ? undefined
                        : templateAttachmentUrl || undefined,
                    mediaType:
                      campaignChannel === ConversationChannel.SMS &&
                      merchant.country === "US"
                        ? undefined
                        : templateAttachmentType || undefined,
                    isOutgoing: true,
                  },
                  ...[
                    (attachedFile
                      ? {
                          mediaType: attachedFile.type,
                          mediaUrl: attachedFile.url,
                        }
                      : campaignMediaUrl ||
                        (templateAttachmentType && templateAttachmentUrl)) &&
                    campaignChannel === ConversationChannel.SMS &&
                    merchant.country === "US"
                      ? {
                          mediaType: attachedFile
                            ? attachedFile.type
                            : templateAttachmentType || campaignMediaType,
                          mediaUrl: attachedFile
                            ? attachedFile.url
                            : templateAttachmentUrl ||
                              campaignMediaUrl ||
                              undefined,
                          isOutgoing: true,
                        }
                      : {},
                  ],
                ],
              },
            }}
          />
        )}
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box textAlign="center">
              <Heading
                size={isBaseSize ? "sm" : "md"}
                textTransform="uppercase"
              >
                Name
              </Heading>
              <Text pt="2">{campaignName}</Text>
            </Box>
            <Box textAlign="center">
              <Heading
                size={isBaseSize ? "sm" : "md"}
                textTransform="uppercase"
              >
                Channel
              </Heading>
              <Flex
                justifyContent="center"
                pt="2"
                gridGap={2}
                alignItems={isBaseSize ? "center" : "baseline"}
                direction={isBaseSize ? "column" : "row"}
              >
                {campaignChannels.map((c) => (
                  <Badge colorScheme="green" size="xs" variant="outline">
                    {c}
                  </Badge>
                ))}
              </Flex>
            </Box>

            {campaignChannels.includes(ConversationChannel.SMS) &&
              segmentsCount > 1 && (
                <Box textAlign="center">
                  <Heading
                    size={isBaseSize ? "sm" : "md"}
                    textTransform="uppercase"
                  >
                    Segments per Message
                  </Heading>
                  <Text pt="2">
                    {`This message will count as ${segmentsCount} SMS messages`}
                  </Text>
                </Box>
              )}

            {campaignStatus === CampaignStatus.DONE &&
              campaignChannels.includes(ConversationChannel.SMS) && (
                <Box textAlign="center">
                  <Tooltip
                    isDisabled={false}
                    label="This helps you understand how much you will be charged for the campaign in total"
                    borderRadius="1rem"
                    px={3}
                    py={1}
                    backgroundColor="rgba(54, 178, 250, 0.9)"
                  >
                    <Heading
                      size={isBaseSize ? "sm" : "md"}
                      textTransform="uppercase"
                    >
                      Total amount of segments
                      <Icon
                        as={InformationIcon}
                        ml={1}
                        __css={{
                          height: "1rem",
                          width: "1rem",
                          path: {
                            fill:
                              colorMode === "dark" ? "gray.200" : "gray.400",
                          },
                        }}
                      />
                    </Heading>
                  </Tooltip>
                  <Text pt="2" fontWeight="bold">
                    {campaignAnalytics?.totalSegments || 0}
                  </Text>
                </Box>
              )}

            {chosenAudience && (
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Audience
                </Heading>
                <Text pt="2">
                  <VStack
                    justifyContent="center"
                    spacing={2}
                    alignItems="center"
                  >
                    <HStack>
                      <Badge
                        colorScheme={
                          audienceCustomersList.totalCount > 0 ? "green" : "red"
                        }
                      >
                        {audienceCustomersList.totalCount} people
                      </Badge>
                      {isBaseSize ? null : (
                        <Link
                          color={`${colorScheme}.400`}
                          href={`/${merchant.id}/contacts?audience_id=${campaignAudienceId}`}
                          target="_blank"
                          display="inline-flex"
                          alignItems="center"
                          gridGap={1}
                        >
                          <Text>{chosenAudience.name}</Text>
                          <Icon as={ExternalLinkIcon} />
                        </Link>
                      )}
                    </HStack>
                    {chosenAudience.criteria ? (
                      <Box>
                        <Alert status="info" variant="subtle" borderRadius="xl">
                          <QueryBuilder
                            entityNamePlural="customers"
                            criteria={chosenAudience.criteria}
                            setCriteria={(_newCriteria) => {}}
                            isDisabled={true}
                            groups={enabledGroups}
                          />
                        </Alert>
                      </Box>
                    ) : null}
                  </VStack>
                </Text>
              </Box>
            )}

            <Box textAlign="center">
              <Heading
                size={isBaseSize ? "sm" : "md"}
                textTransform="uppercase"
              >
                Pricing
              </Heading>
              <Text pt="2">
                <Flex
                  justifyContent="center"
                  gridGap={2}
                  alignItems={isBaseSize ? "center" : "baseline"}
                  direction={isBaseSize ? "column" : "row"}
                >
                  <Link
                    color={`${colorScheme}.400`}
                    href="https://www.getfuzey.com/pricing-whatsapp-sms"
                    target="_blank"
                    display="inline-flex"
                    alignItems="center"
                    gridGap={1}
                  >
                    <Text>Campaigns Pricing</Text>
                    <Icon as={ExternalLinkIcon} />
                  </Link>
                </Flex>
              </Text>
            </Box>

            {campaignSendingStrategy && (
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Sending Strategy
                </Heading>
                <Text pt="2">
                  To all possible channels{" "}
                  <Icon
                    as={InformationIcon}
                    __css={{
                      path: {
                        fill:
                          colorMode === "dark" ? "orange.200" : "orange.500",
                      },
                    }}
                  />
                </Text>
              </Box>
            )}
            {campaignAdditionalCost && (
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Additional Cost
                </Heading>
                <Flex
                  pt="2"
                  alignItems="baseline"
                  justifyContent="center"
                  direction={isBaseSize ? "column" : "row"}
                >
                  <Alert status="success" size="xs" borderRadius="lg">
                    <AlertIcon />
                    <Text fontWeight="semibold">No extra charges!</Text>
                    <Link
                      href="/google"
                      color="blue.500"
                      textDecorationLine="underline"
                      ml={2}
                    >
                      See why
                    </Link>
                  </Alert>
                </Flex>
              </Box>
            )}
          </Stack>
        </CardBody>

        {campaignStatus === CampaignStatus.DONE && (
          <CardBody>
            <Stack spacing="6">
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Delivered
                </Heading>
                <Flex
                  pt="2"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Progress
                    w="100%"
                    style={{
                      borderRadius: "99999",
                    }}
                    value={
                      ((campaignAnalytics?.totalDelivered || 0) /
                        (campaignAnalytics?.total || 0)) *
                      100
                    }
                    colorScheme={getProgressColorScheme(
                      ((campaignAnalytics?.totalDelivered || 0) /
                        (campaignAnalytics?.total || 0)) *
                        100
                    )}
                  />
                  <Text fontSize="sm">
                    {campaignAnalytics?.totalDelivered || 0}/
                    {campaignAnalytics?.total || 0}
                  </Text>
                </Flex>
              </Box>
              {campaignChannel === ConversationChannel.WHATSAPP ? (
                <Box textAlign="center">
                  <Heading
                    size={isBaseSize ? "sm" : "md"}
                    textTransform="uppercase"
                  >
                    Read
                  </Heading>
                  <Flex
                    pt="2"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Progress
                      w="100%"
                      style={{
                        borderRadius: "99999",
                      }}
                      value={
                        ((campaignAnalytics?.totalRead || 0) /
                          (campaignAnalytics?.total || 0)) *
                        100
                      }
                      colorScheme={getProgressColorScheme(
                        ((campaignAnalytics?.totalRead || 0) /
                          (campaignAnalytics?.total || 0)) *
                          100
                      )}
                    />
                    <Text fontSize="sm">
                      {campaignAnalytics?.totalRead || 0}/
                      {campaignAnalytics?.total || 0}
                    </Text>
                  </Flex>
                </Box>
              ) : null}
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Failed
                </Heading>
                <Flex
                  pt="2"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Progress
                    w="100%"
                    style={{
                      borderRadius: "99999",
                    }}
                    value={
                      ((campaignAnalytics?.totalFailed || 0) /
                        (campaignAnalytics?.total || 0)) *
                      100
                    }
                    colorScheme={getProgressColorScheme(
                      ((campaignAnalytics?.totalFailed || 0) /
                        (campaignAnalytics?.total || 0)) *
                        100,
                      false,
                      true
                    )}
                  />
                  <Text fontSize="sm">
                    {campaignAnalytics?.totalFailed || 0}/
                    {campaignAnalytics?.total || 0}
                  </Text>
                </Flex>
              </Box>
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Replied
                </Heading>
                <Flex
                  pt="2"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Progress
                    w="100%"
                    style={{
                      borderRadius: "99999",
                    }}
                    value={
                      ((campaignAnalytics?.totalReplied || 0) /
                        (campaignAnalytics?.totalDelivered || 0)) *
                      100
                    }
                    colorScheme={getProgressColorScheme(
                      ((campaignAnalytics?.totalReplied || 0) /
                        (campaignAnalytics?.totalDelivered || 0)) *
                        100,
                      true
                    )}
                  />
                  <Text fontSize="sm">
                    {campaignAnalytics?.totalReplied || 0}/
                    {campaignAnalytics?.totalDelivered || 0}
                  </Text>
                </Flex>
              </Box>
            </Stack>
          </CardBody>
        )}
      </Card>

      {campaignStatus === CampaignStatus.DONE && campaignCustomersList && (
        <Flex
          direction="column"
          w={isBaseSize ? "100%" : "80%"}
          mx="auto"
          mt={8}
        >
          <Text fontSize="xl" fontWeight={700} my={3}>
            Recipients
          </Text>
          <AudienceCustomersList customers={campaignCustomersList.contacts} />
        </Flex>
      )}
      {chosenAction && chosenAction === "Schedule" && (
        <Box w={isBaseSize ? "100%" : "60%"} mx="auto">
          <FormControl mt={6} isRequired={true}>
            <FormLabel fontWeight={700}>Schedule for</FormLabel>
            <Box
              rounded="full"
              borderWidth="1px"
              borderColor={`${colorScheme}.400`}
              textAlign="left"
              py={1}
              px={3}
              css={{
                input: {
                  width: "100%",
                  "::placeholder": {
                    fontStyle: "italic",
                  },
                  backgroundColor: "inherit",
                },
                ".react-datepicker-wrapper": {
                  width: "100%",
                },
              }}
            >
              <ReactDatePicker
                id="campaign-form-scheduled-for"
                selected={
                  campaignScheduledFor ? new Date(campaignScheduledFor) : null
                }
                placeholderText="Choose a date and time for the campaign to be sent"
                onChange={(newDate) =>
                  setScheduledFor((newDate || new Date()).toISOString())
                }
                startDate={new Date()}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, h:mm aa"
              />
            </Box>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default Preview;
