import { useAuth0 } from "@auth0/auth0-react";
import { Box, FormControl, FormLabel, useColorMode } from "@chakra-ui/react";
import MessageInputCoWriter from "components/campaigns/form/steps/message/MessageInputCoWriter";
import MessageInputOverlay from "components/campaigns/form/steps/message/MessageInputOverlay";
import MessageInputToolbar from "components/campaigns/form/steps/message/MessageInputToolbar";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import FileDomain from "entities/domain/file";
import { TemplateCategory } from "entities/domain/templates/full_template";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import MarketingPrefix from "../MarketingPrefix";
import TemplateAttachment from "../TemplateAttachment";
import { UnsubscribeSuffix } from "../UnsubscribeSuffix";

interface TemplateTextProps {
  isDisabled: boolean;
  templateCategory: TemplateCategory | null;
  templateChannels: string[];
  templateMediaUrl: string | null;
  templateMediaType: string | null;
  isSuffixIncluded: boolean;
  editorText: { value: string };
  mandatoryPrefix: string;
  attachedFile: FileDomain | null;
  setIsSuffixIncluded: (isIncluded: boolean) => void;
  setMandatoryPrefix: (newPrefix: string) => void;
  setEditorText: (newEditorText: { value: string }) => void;
  setTemplateText: (text: string) => void;
  setTemplateMediaUrl: (mediaUrl: string | null) => void;
  setTemplateMediaType: (mediaType: string | null) => void;
  setAttachedFile: (file: FileDomain | null) => void;
}

const MAX_LENGTH_WHATSAPP = 1024;

const TemplateText = ({
  isDisabled,
  templateCategory,
  templateChannels,
  templateMediaUrl,
  templateMediaType,
  attachedFile,
  isSuffixIncluded,
  editorText,
  mandatoryPrefix,
  setIsSuffixIncluded,
  setMandatoryPrefix,
  setEditorText,
  setTemplateText,
  setTemplateMediaUrl,
  setTemplateMediaType,
  setAttachedFile,
}: TemplateTextProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const [addAttachmentOrUndefined, setAddAttachmentOrUndefined] = useState<
    undefined | ((file: FileDomain) => Promise<void>)
  >(undefined);
  const [isAskingSuggestion, setIsAskingSuggestion] = useState<boolean>(false);

  const editorReference = useRef<LexicalEditor | undefined>();

  const availableCustomFields = useAvailableCustomFields();

  const insertEmoji = useCallback(
    ({ native }: { native: string }) => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        selection.insertText(` ${native} `);

        setTemplateText($getRoot().getTextContent());
      });

      editorReference.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    },
    [editorReference]
  );

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return;
        }

        selection.insertText(` ${someText} `);

        setTemplateText($getRoot().getTextContent());
      });

      editorReference.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    },
    [editorReference]
  );

  const askAiForHelp = useCallback(async (aiPrompt: string) => {
    setIsAskingSuggestion(true);

    let response: { suggestion: string } | null = null;

    try {
      response = await InboxService.getSuggestedTemplate(
        auth0Context,
        merchant.id,
        aiPrompt
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Could not get AI suggestion for campaigns", e);
    } finally {
      setIsAskingSuggestion(false);
    }

    if (response === null) {
      return;
    }

    setTemplateText(response.suggestion);
    setEditorText({
      value: response!.suggestion,
    });
  }, []);

  const addAttachment = async (file: FileDomain) => {
    setAttachedFile(file);
  };

  const setPrefix = useCallback(
    (newPrefix: string) => setMandatoryPrefix(newPrefix),
    []
  );
  const enabledPlugins = useMemo(() => {
    const newEnabledPlugins = [];

    if (templateChannels.includes(ConversationChannel.WHATSAPP)) {
      newEnabledPlugins.push(EditorUltraPlugin.MAXLENGTH);
    }

    return newEnabledPlugins;
  }, [templateChannels]);

  useEffect(() => {
    if (
      templateChannels.includes(ConversationChannel.EMAIL) ||
      templateChannels.includes(ConversationChannel.SMS)
    ) {
      setAddAttachmentOrUndefined(() => addAttachment);
    } else {
      setAddAttachmentOrUndefined(undefined);
    }
  }, [templateChannels]);

  return (
    <>
      {isDisabled ? null : (
        <FormControl>
          <MessageInputCoWriter
            askAiForHelp={askAiForHelp}
            motive="template"
            isDisabled={false}
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Message</FormLabel>
        <Box
          position="relative"
          background={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          borderStyle="solid"
          borderWidth="2px"
          borderColor={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
          p="1rem"
          px="1.5rem"
          mb={36}
        >
          <MessageInputOverlay isShown={isAskingSuggestion} />
          {templateCategory === TemplateCategory.MARKETING && (
            <MarketingPrefix
              prefix={mandatoryPrefix}
              setPrefix={setPrefix}
              isDisabled={isDisabled}
            />
          )}
          <EditorUltra
            maxHeight="450px"
            isDisabled={isDisabled}
            isEditable={!isDisabled}
            defaultText={editorText}
            customFieldsAlwaysGreen={true}
            placeholder="Enter some text"
            editorReference={editorReference}
            setText={setTemplateText}
            enabledPlugins={enabledPlugins}
            channels={templateChannels as ConversationChannel[]}
            maxCharLength={
              templateChannels.includes(ConversationChannel.WHATSAPP)
                ? MAX_LENGTH_WHATSAPP
                : undefined
            }
            exceededText="This template message cannot be more than 1,024 characters. Please shorten it before submitting."
            customFields={availableCustomFields}
          />
          <TemplateAttachment
            mediaUrl={templateMediaUrl || attachedFile?.url || null}
            mediaType={templateMediaType || attachedFile?.type || null}
            onAttachmentRemove={
              isDisabled
                ? undefined
                : () => {
                    setTemplateMediaUrl(null);
                    setTemplateMediaType(null);
                    setAttachedFile(null);
                  }
            }
          />
          {templateCategory === TemplateCategory.MARKETING && (
            <UnsubscribeSuffix
              isIncluded={isSuffixIncluded}
              setIsIncluded={setIsSuffixIncluded}
            />
          )}
          {isDisabled ? null : (
            <MessageInputToolbar
              insertEmoji={insertEmoji}
              insertIntoText={insertIntoText}
              addAttachment={addAttachmentOrUndefined}
              customFields={availableCustomFields}
            />
          )}
        </Box>
      </FormControl>
    </>
  );
};

export default memo(TemplateText);
