import { useColorMode, VStack } from "@chakra-ui/react";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import SkeletonOverlay from "./SkeletonOverlay";

interface WrapperProps {
  children: React.ReactNode;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const Wrapper: ForwardRefRenderFunction<HTMLDivElement, WrapperProps> = (
  { children, onKeyDown },
  ref
) => {
  const { colorMode } = useColorMode();

  return (
    <VStack
      ref={ref}
      spacing={0}
      position="relative"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      overflow="hidden"
      width="100%"
      h="100%"
      borderRadius={{
        base: 0,
        lg: "xl",
      }}
      justifyContent="space-between"
      onKeyDown={onKeyDown}
    >
      <SkeletonOverlay />
      {children}
    </VStack>
  );
};

export default forwardRef(Wrapper);
