import { useAuth0 } from "@auth0/auth0-react";
import {
  HStack,
  Spacer,
  Textarea,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import AddCustomField from "components/shared/AddCustomField";
import WhatsappMarkdownToolbar from "components/user-settings/shared/WhatsappMarkdownToolbar";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { MessageDirection } from "entities/domain/conversations/message-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import TemplateDomain from "entities/domain/templates";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import useDebounce from "hooks/use-debounce";
import { useScrollWithShadow } from "hooks/use-scroll-with-shadows";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import {
  MessageInputTab,
  changeMessageInputText,
  setMessageInputTemplate,
} from "redux/features/conversations";
import { messagesSelector } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  clearQueueScrollLocks,
  disablePageScroll,
  enablePageScroll,
} from "scroll-lock";
import ContactsService from "services/contacts";
import InboxService from "services/inbox";
import { isAndroid, isIOSPlatform, isMobileApp } from "util/methods";
import Attachments from "../../Attachments";
import ClearInputButton from "../../ClearInputButton";
import Content from "../../Content";
import EmptyCustomFieldsWarning from "../../EmptyCustomFieldsWarning";
import Footer from "../../Footer";
import InputActions from "../../InputActions";
import MaxLengthWarning from "../../MaxLengthWarning";
import SendButton from "../../SendButton";
import TypingZoneOverlay from "../../TypingZoneOverlay";
import Wrapper from "../../Wrapper";
import FirstOutreachMessage from "./FirstOutreachMessage";
import MessagingImpossible from "./MessagingImpossible";

interface WhatsappInputProps {}

const WHATSAPP_LIMIT = 4096; // 1024 is for templates

const WhatsappInput: React.FC<WhatsappInputProps> = () => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const {
    activeConversation,
    activeConversationId,
    templates: conversationTemplates,
    messageInput: { text, attachments, template, activeTab },
  } = useAppSelector((state) => state.conversations);
  const [localText, setLocalText] = useState<string>(
    template ? template.text : text
  );
  const debouncedLocalText = useDebounce(localText, 500);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);
  const [isApproachingLimit, setIsApproachingLimit] = useState<boolean>(false);
  const [isLoadingSuggestion, setIsLoadingSuggestion] =
    useState<boolean>(false);
  const { search } = useLocation();
  const conversationMessages = useAppSelector(messagesSelector);
  const lastOutgoingMessage = [...conversationMessages]
    .reverse()
    .find((m) => m.direction === MessageDirection.OUTGOING);
  const toast = useToast();

  useEffect(() => {
    if (text === debouncedLocalText) {
      return;
    }

    dispatch(changeMessageInputText(debouncedLocalText));
  }, [debouncedLocalText]);

  const templatesShortcuts = (
    activeConversationId
      ? conversationTemplates[activeConversationId] || []
      : []
  ).reduce(
    (shortcuts: { [key: string]: TemplateDomain }, t: TemplateDomain) => {
      const newShortcuts = { ...shortcuts };

      if (t.shortcut) {
        newShortcuts[t.shortcut] = t;
      }

      return newShortcuts;
    },
    {}
  );

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement> | string) => {
      const value = typeof event === "string" ? event : event.target.value;

      if (value === localText) {
        return;
      }

      setLocalText(value);

      if (!value) {
        dispatch(setMessageInputTemplate(null));
      }

      const templateShortcut = /^\/(.*)$/;
      const shortcutAttempt = value.match(templateShortcut);
      const foundTemplate =
        shortcutAttempt && templatesShortcuts[shortcutAttempt[1]];

      if (foundTemplate) {
        dispatch(setMessageInputTemplate(foundTemplate));
        setLocalText(
          TemplateDomain.getTextFromTemplate(
            foundTemplate.text,
            foundTemplate.customFields
          )
        );

        return;
      }
    },
    [localText, templatesShortcuts]
  );

  useEffect(() => {
    if (!template) {
      return;
    }

    dispatch(changeMessageInputText(template.text));
  }, [template?.id]);

  useEffect(() => {
    if (text === localText) {
      return;
    }

    setLocalText(text);
  }, [text]);

  const availableCustomFields = useAvailableCustomFields(template);

  const isDisabled = useMemo(() => {
    if (activeConversation?.isChannelDisconnected(merchant)) {
      return true;
    }

    if (isLimitExceeded) {
      return true;
    }

    const isFileAttached = !!attachments.length;
    const messageIsEmpty = (localText.trim().length || 0) === 0;
    const messageExceedsLimits = WHATSAPP_LIMIT
      ? (localText.length || 0) > WHATSAPP_LIMIT
      : false;
    const templateHasCustomFieldsWithoutValue = template
      ? TemplateDomain.containsCustomFieldsWithoutValue(
          template.text,
          template.customFields
        )
      : false;

    if (templateHasCustomFieldsWithoutValue) {
      return true;
    }

    return !(
      isFileAttached ||
      !messageIsEmpty ||
      (!messageIsEmpty && !messageExceedsLimits)
    );
  }, [
    attachments,
    localText,
    template,
    activeConversation,
    merchant,
    isLimitExceeded,
  ]);

  const handleCreate = async (chanId: string, messageText?: string) => {
    try {
      const newConversation =
        await InboxService.createConversationWithChannelId(
          auth0Context,
          chanId,
          merchant.id
        );

      navigate(
        {
          pathname: `/${merchant.id}/inbox/${newConversation.id}`,
          search: createSearchParams(search).toString(),
        },
        {
          state: { messageText },
        }
      );
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Couldn't create SMS conversation with the customer.",
      });

      return;
    }
  };

  const insertText = useCallback(
    (newPieceOfText: string) => {
      if (!textAreaRef.current) {
        return;
      }

      const { selectionStart, selectionEnd } = textAreaRef.current;

      const newText = [
        localText.slice(0, selectionStart),
        newPieceOfText,
        localText.slice(selectionEnd),
      ].join("");

      setLocalText(newText);
      textAreaRef.current.focus();
    },
    [textAreaRef.current, localText]
  );

  const messagePlaceholder = useMemo(() => {
    if (!activeConversation) {
      return "";
    }

    if (activeConversation.isChannelDisconnected(merchant)) {
      return `Please reconnect your ${ChannelDomain.getChannelName(
        activeConversation.channel
      )} account to send message`;
    }

    return "Type here to send a message";
  }, [activeConversation, merchant.channels]);

  const isEditable = useMemo(() => {
    if (template) {
      return false;
    }

    if (!activeConversation) {
      return false;
    }

    return (
      !activeConversation.isTemplatesOnly() &&
      !activeConversation.isChannelDisconnected(merchant)
    );
  }, [template, activeConversation, merchant]);

  const [forceOverlay, setForceOverlay] = useState<boolean>(true);

  useEffect(() => {
    if (
      template ||
      (activeConversation && !activeConversation.isTemplatesOnly())
    ) {
      setForceOverlay(false);
    } else if (activeConversation && activeConversation.isTemplatesOnly()) {
      setForceOverlay(true);
    }
  }, [
    template,
    activeConversation?.id,
    activeConversation?.allowedMessagesType,
  ]);

  const { scrollHelper, onScrollHandler } = useScrollWithShadow();

  useEffect(() => {
    if (!textAreaRef.current || !isBaseSize) {
      return;
    }

    const triggerScrollTimer = setTimeout(() => {
      if (!textAreaRef.current) {
        return;
      }

      onScrollHandler({
        target: textAreaRef.current,
      } as unknown as React.UIEvent<HTMLTextAreaElement, UIEvent>);
    }, 500);

    return () => {
      clearTimeout(triggerScrollTimer);
    };
  }, [textAreaRef.current, template, activeTab, isBaseSize]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const updateKeyboardPadding = () => {
    if (!window.visualViewport || !wrapperRef.current) {
      return;
    }

    const keyboardHeight = window.innerHeight - window.visualViewport.height;
    wrapperRef.current.style.paddingBottom = `${keyboardHeight}px`;
  };

  const [isTextAreaFocused, setIsTextAreaFocused] = useState<boolean>(
    isAndroid() && isMobileApp()
  );

  useEffect(() => {
    if (!(isAndroid() && isMobileApp())) {
      return;
    }

    if (wrapperRef.current) {
      wrapperRef.current.style.paddingBottom = isTextAreaFocused
        ? `${window.innerHeight * 0.4}px`
        : "0px";
    }

    setTimeout(() => {
      // if (window.visualViewport) {
      //   updateKeyboardPadding();
      // } else if (wrapperRef.current) {
      if (wrapperRef.current) {
        wrapperRef.current.style.paddingBottom = isTextAreaFocused
          ? `${window.innerHeight * 0.4}px`
          : "0px";
      }
    }, 250);

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.style.paddingBottom = `0px`;
      }
    };
  }, [isTextAreaFocused]);

  return (
    <Wrapper ref={wrapperRef}>
      <TypingZoneOverlay isShown={isLoadingSuggestion} borderRadius="xl" />
      <MessagingImpossible
        template={template}
        conversation={activeConversation}
        openSmsConversation={async (messageText?: string) => {
          if (!activeConversation) {
            toast({
              status: "error",
              title: "Couldn't open SMS conversation",
            });

            return;
          }

          let smsChannel: CustomerChannelDomain | undefined;

          try {
            const customer = await ContactsService.getContact(
              auth0Context,
              activeConversation.customerId,
              merchant.groupId
            );
            smsChannel = customer.channels.find(
              (c) => c.type === ConversationChannel.SMS
            );
          } catch (_error: unknown) {
            toast({
              status: "error",
              title: "Couldn't find customer",
            });

            return;
          }

          if (!smsChannel) {
            toast({
              status: "error",
              title: "Couldn't find customer's SMS channel",
            });

            return;
          }
          await handleCreate(smsChannel.id!, messageText);
        }}
        lastMessage={lastOutgoingMessage}
      >
        {!forceOverlay ? (
          <Content
            isEditable={isEditable}
            scrollHelper={isBaseSize ? scrollHelper : undefined}
            showHeader={true}
            topRightElement={
              <HStack w="100%" px={2}>
                {isEditable && activeTab !== MessageInputTab.PREVIEW ? (
                  <WhatsappMarkdownToolbar
                    size="xs"
                    textAreaRef={textAreaRef}
                    setText={setLocalText}
                  />
                ) : null}
                <Spacer />
                {!template ? null : (
                  <ClearInputButton
                    isDisabled={isDisabled}
                    onClick={() => {
                      setLocalText("");
                    }}
                  />
                )}
              </HStack>
            }
          >
            <Textarea
              as={TextareaAutosize}
              autoFocus={true}
              data-private={true}
              isDisabled={!isEditable}
              data-scroll-lock-scrollable
              onFocus={() => {
                setIsTextAreaFocused(true);

                if (!isIOSPlatform()) {
                  return false;
                }

                disablePageScroll();
              }}
              onBlur={() => {
                setIsTextAreaFocused(false);

                if (!isIOSPlatform()) {
                  return false;
                }

                clearQueueScrollLocks();
                enablePageScroll();
              }}
              onScroll={isBaseSize ? onScrollHandler : undefined}
              minHeight="5rem"
              maxHeight="100%"
              ref={textAreaRef}
              placeholder={messagePlaceholder}
              px={2}
              pt={2}
              pb={isBaseSize ? 0 : 2}
              lineHeight="inherit"
              value={localText}
              onChange={onTextChange}
              outline="none"
              border="none"
              borderRadius={0}
              boxShadow="none"
              resize="none"
            />
            <Attachments />
          </Content>
        ) : (
          <FirstOutreachMessage template={template} isShown={forceOverlay} />
        )}
        <Footer>
          <InputActions
            setIsLoadingSuggestion={setIsLoadingSuggestion}
            insertText={insertText}
          />
          {!template ||
          !isEditable ||
          activeTab === MessageInputTab.PREVIEW ? null : (
            <AddCustomField
              useSmallVersion={true}
              isDisabled={false}
              customFields={availableCustomFields}
              size="xs"
              onCustomFieldSelect={(cf) => insertText(cf)}
              addNewCustomField={(key, value) => {
                dispatch(
                  setMessageInputTemplate(
                    Object.setPrototypeOf(
                      {
                        ...template,
                        customFields: {
                          ...template.customFields,
                          [key]: value,
                        },
                      },
                      TemplateDomain.prototype
                    )
                  )
                );
                insertText(key);
              }}
            />
          )}
          <MaxLengthWarning
            max={WHATSAPP_LIMIT}
            currentLength={localText.length}
            setIsLimitExceeded={setIsLimitExceeded}
            setIsApproachingLimit={setIsApproachingLimit}
          />
          {isLimitExceeded || isApproachingLimit ? null : (
            <EmptyCustomFieldsWarning text={localText} />
          )}
          <Spacer />
          <SendButton
            text={localText}
            isDisabled={isDisabled}
            onMessageSent={() => {
              setLocalText("");
            }}
            textAreaRef={textAreaRef}
          />
        </Footer>
      </MessagingImpossible>
    </Wrapper>
  );
};

export default WhatsappInput;
