import { format, isSameDay, parseISO } from "date-fns";

export default class CalendarBookingDomain {
  id: string;

  startAt: string;

  endAt: string;

  startTimezone: string;

  endTimezone: string;

  agentIds: number[];

  customerId: number;

  title: string;

  description: string;

  notifyChannelSelected: boolean;

  formatTitle(): string {
    return this.title === "" ? "(No title)" : this.title;
  }

  public getAgentKey(): string {
    return this.agentIds.map((ag) => ag).toString();
  }

  isSameDay(): boolean {
    return isSameDay(parseISO(this.startAt), parseISO(this.endAt));
  }

  public getFormatDate(): string {
    const start = parseISO(this.startAt);
    const end = parseISO(this.endAt);

    return this.isSameDay()
      ? `${format(start, "EEEE d MMMM, h:mm a")} - ${format(end, "h:mm a")}${
          this.startTimezone ? ` (${this.startTimezone})` : ""
        }`
      : `${format(start, "EEEE d MMMM, h:mm a")} - ${format(
          end,
          "EEEE d MMMM, h:mm a"
        )}${this.startTimezone ? ` (${this.startTimezone})` : ""}`;
  }

  constructor(
    id: string,
    startAt: string,
    endAt: string,
    startTimezone: string,
    endTimezone: string,
    agentIds: number[],
    customerId: number,
    title: string,
    description: string,
    notifyChannelSelected: boolean
  ) {
    this.id = id;
    this.startAt = startAt;
    this.endAt = endAt;
    this.startTimezone = startTimezone;
    this.endTimezone = endTimezone;
    this.agentIds = agentIds;
    this.customerId = customerId;
    this.title = title;
    this.description = description;
    this.notifyChannelSelected = notifyChannelSelected;
  }
}
