import { Flex, Icon, Input, Text, useColorMode } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from "assets/icons/edit-ds.svg";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { MessageSubType } from "entities/domain/conversations/message-domain";
import React, { ChangeEventHandler, memo, useMemo, useState } from "react";
import { messagesSelector } from "redux/features/messages";
import { useAppSelector } from "redux/hooks";
import { getScrollShadowStyles } from "../../Content";

interface SubjectLineProps {
  handleTextChange: ChangeEventHandler<HTMLInputElement>;
  text: string;
  isDisabled: boolean;
}

const SubjectLine = ({
  text,
  handleTextChange,
  isDisabled,
}: SubjectLineProps) => {
  const conversationMessages = useAppSelector(messagesSelector);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const lastMessage = [...conversationMessages]
    .filter(({ subType }) => subType === MessageSubType.REGULAR)
    .pop();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const { colorMode } = useColorMode();
  const scrollShadowStyles = useMemo(() => {
    return getScrollShadowStyles(colorMode, true, true);
  }, [colorMode]);

  return (
    <>
      <Flex position="relative" width="100%" _after={scrollShadowStyles}>
        <Input
          value={text}
          zIndex={0}
          onBlur={() => setIsEditingSubject(false)}
          onChange={handleTextChange}
          placeholder="Enter subject"
          py={2}
          px={2}
          variant="unstyled"
          borderRadius={0}
          isDisabled={isDisabled}
        />
        {!isEditingSubject && (
          <Flex
            position="absolute"
            zIndex={1}
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={() => {
              if (isDisabled) {
                return;
              }

              if (!text) {
                setIsEditingSubject(true);

                return;
              }

              if (lastMessage && text !== lastMessage.title) {
                setIsEditingSubject(true);
              } else {
                setShowConfirmationModal(true);
              }
            }}
            backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
            _hover={{
              backgroundColor:
                colorMode === "dark"
                  ? `${colorScheme}.900`
                  : `${colorScheme}.100`,
            }}
            width="100%"
            height="100%"
            justifyContent="flex-start"
            alignItems="center"
            color={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
            gridGap={2}
          >
            <Text
              pl={2}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              data-private={true}
              fontWeight="bold"
            >
              Subject: {text || "No subject"}
            </Text>
            <Icon
              as={EditIcon}
              boxSize="1rem"
              __css={{
                path: {
                  fill:
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`,
                },
              }}
            />
          </Flex>
        )}
      </Flex>
      <ConfirmationDialog
        headerText="Change Subject Line?"
        messageText="Changing the subject line will initiate a new e-mail thread with the recipient. Would you like to continue?"
        buttonText="Continue"
        isOpen={showConfirmationModal}
        setIsOpen={setShowConfirmationModal}
        confirmationCallback={() => {
          setIsEditingSubject(true);
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default memo(SubjectLine);
