import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { SocketEventTypes } from "entities/ISocketArgs";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { Socket, io } from "socket.io-client";
import { socketUrl } from "util/constants";

type WebSocketProviderProps = {
  children: ReactNode;
};

type SocketEventHandler<T> = (data: T) => void;

interface WebSocketContextData {
  socket: Socket;
  addEventHandler: <K extends keyof SocketEventTypes>(
    eventType: K,
    handler: SocketEventHandler<SocketEventTypes[K]>
  ) => void;
  removeEventHandler: <K extends keyof SocketEventTypes>(
    eventType: K,
    handler: SocketEventHandler<SocketEventTypes[K]>
  ) => void;
}

const WebSocketContext = createContext({} as WebSocketContextData);

const ONE_SECOND = 1000;

const getRandomIntegerBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export function WebSocketProvider({ children }: WebSocketProviderProps) {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { getAccessTokenSilently }: Auth0ContextInterface = useAuth0();

  const [socket, setSocket] = useState<Socket>({} as Socket);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [connect, setConnect] = useState<boolean>(true);
  const [reconnectOnErrorAttempts, setReconnectOnErrorAttempts] =
    useState<number>(0);
  const [eventHandlers, setEventHandlers] = useState<{
    [K in keyof SocketEventTypes]?: Function[];
  }>({});

  const addEventHandler = useCallback(
    <K extends keyof SocketEventTypes>(
      eventType: K,
      handler: SocketEventHandler<SocketEventTypes[K]>
    ) => {
      setEventHandlers((prevHandlers) => ({
        ...prevHandlers,
        [eventType]: [...(prevHandlers[eventType] || []), handler],
      }));
    },
    []
  );

  const removeEventHandler = useCallback(
    <K extends keyof SocketEventTypes>(
      eventType: K,
      handler: SocketEventHandler<SocketEventTypes[K]>
    ) => {
      setEventHandlers((prevHandlers) => ({
        ...prevHandlers,
        [eventType]:
          prevHandlers[eventType]?.filter((h) => h !== handler) || [],
      }));
    },
    []
  );

  useEffect(() => {
    if (connect) {
      if (!socket.connected) {
        getAccessTokenSilently().then((token) => {
          const headers: {
            [key: string]: string;
          } = {
            Authorization: `Bearer ${token}`,
          };

          if (process.env.REACT_APP_SENTRY_RELEASE) {
            headers["App-Version"] = process.env.REACT_APP_SENTRY_RELEASE;
          }

          if (process.env.REACT_APP_BUILD_NUMBER) {
            headers["Build-Number"] = process.env.REACT_APP_BUILD_NUMBER;
          }

          setSocket(
            io(socketUrl, {
              extraHeaders: headers,
              forceNew: true,
            })
          );
          setConnect(false);
          setIsLoaded(true);
        });
      } else {
        setConnect(false);
        setIsLoaded(true);
      }
    }
  }, [connect]);

  useEffect(() => {
    return () => {
      if (isLoaded) {
        socket.disconnect();
      }
      setIsLoaded(false);
    };
  }, [socket]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    socket.on("connect_error", (error: unknown) => {
      setIsLoaded(false);

      if (reconnectOnErrorAttempts > 2) {
        // eslint-disable-next-line
        console.error(
          "Socket connect error",
          error,
          merchant?.id,
          currentAgent?.id.toString()
        );

        setReconnectOnErrorAttempts(0);
        socket.disconnect();

        return;
      }

      setReconnectOnErrorAttempts(reconnectOnErrorAttempts + 1);
      setTimeout(() => {
        setConnect(true);
      }, ONE_SECOND * getRandomIntegerBetween(5, 30));
    });
  }, [socket]);

  useEffect(() => {
    if (isLoaded) {
      socket?.on("disconnect", (reason: Socket.DisconnectReason) => {
        setIsLoaded(false);

        if (reconnectOnErrorAttempts > 100) {
          // eslint-disable-next-line
          console.error(
            "Socket disconnect event",
            merchant?.id,
            currentAgent?.id.toString(),
            reason
          );

          setReconnectOnErrorAttempts(0);
        } else {
          setReconnectOnErrorAttempts(reconnectOnErrorAttempts + 1);
        }

        setTimeout(() => {
          setConnect(true);
        }, ONE_SECOND);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (!socket || Object.keys(socket).length === 0) {
      return;
    }

    const eventListener = <K extends keyof SocketEventTypes>(
      eventType: K,
      data: any
    ) => {
      const specificEventHandlers = eventHandlers[eventType] as
        | SocketEventHandler<SocketEventTypes[K]>[]
        | undefined;
      specificEventHandlers?.forEach((handler) => handler(data));
    };

    const eventTypes = Object.keys(eventHandlers) as (keyof SocketEventTypes)[];
    eventTypes.forEach((eventType) => {
      socket.on(eventType, (data) => {
        eventListener(eventType, data);
      });
    });

    return () => {
      eventTypes.forEach((eventType) => {
        socket.off(eventType, (data) => eventListener(eventType, data));
      });
    };
  }, [socket, eventHandlers]);

  return (
    <WebSocketContext.Provider
      value={{ socket, addEventHandler, removeEventHandler }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export const useWebSocket = () => useContext(WebSocketContext);
