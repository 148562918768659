import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Input,
  List,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as BracesIcon } from "assets/icons/braces-svgrepo-com.svg";
import React, { useCallback, useState } from "react";
import { FaPlus } from "react-icons/fa";
import AddCustomFieldModal from "./AddCustomFieldModal";
import CustomFieldMenuItem, {
  humanizeCustomFieldKey,
} from "./CustomFieldMenuItem";

interface AddCustomFieldProps {
  useSmallVersion?: boolean;
  isDisabled: boolean;
  customFields: { [key: string]: string };
  onCustomFieldSelect: (cf: string) => void;
  size?: "xs" | "sm" | "md" | "lg";
  addNewCustomField: (key: string, value: string) => void;
  shouldAskForExampleValues?: boolean;
}

const AddCustomField: React.FC<AddCustomFieldProps> = ({
  isDisabled,
  customFields,
  size = "sm",
  useSmallVersion = false,
  onCustomFieldSelect,
  addNewCustomField,
  shouldAskForExampleValues = false,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const [newCustomFieldKey, setNewCustomFieldKey] = useState<string>("");

  const [isPersonalizedCustomField, setIsPersonalizedCustomField] =
    useState<boolean>(true);

  const createCustomFieldModal = useDisclosure();

  const onInitialSelect = useCallback(
    (key: string) => {
      if (customFields[key] === "" && shouldAskForExampleValues) {
        setIsPersonalizedCustomField(false);
        setNewCustomFieldKey(key);
        createCustomFieldModal.onOpen();
        return;
      }
      onCustomFieldSelect?.(`{${key}}`);
    },
    [customFields, onCustomFieldSelect, createCustomFieldModal]
  );

  const [searchValue, setSearchValue] = useState("");

  const customFieldsButton =
    isBaseSize || useSmallVersion ? (
      <IconButton
        isDisabled={isDisabled}
        colorScheme={!isDisabled ? "green" : "gray"}
        size={size}
        p={0}
        icon={
          <Icon
            as={BracesIcon}
            __css={{
              "path:last-child": {
                fill: colorMode === "dark" ? "black" : "white",
              },
            }}
          />
        }
        aria-label="Add Custom Field"
      />
    ) : (
      <Button
        isDisabled={isDisabled}
        size={size}
        colorScheme={!isDisabled ? "green" : "gray"}
        rightIcon={<ChevronDownIcon />}
        leftIcon={
          <Icon
            as={BracesIcon}
            __css={{
              "path:last-child": {
                fill: colorMode === "dark" ? "black" : "white",
              },
            }}
          />
        }
      >
        Add Custom Field
      </Button>
    );

  return !isDisabled ? (
    <>
      <Popover>
        <PopoverTrigger>{customFieldsButton}</PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={0}>
              <Box p={2}>
                <Input
                  placeholder="Search fields..."
                  size="sm"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  autoFocus
                />
              </Box>
              <Divider />
              <List maxHeight="300px" overflowY="auto">
                {Object.entries(customFields)
                  .filter(([key, _value]) =>
                    humanizeCustomFieldKey(key)
                      .toLowerCase()
                      .includes(searchValue.toLowerCase().trim())
                  )
                  .map(([key, _value]) => (
                    <CustomFieldMenuItem
                      key={key}
                      customFieldKey={key}
                      onClick={() => {
                        if (isDisabled) {
                          return;
                        }
                        onInitialSelect(key);
                      }}
                    />
                  ))}
              </List>
              <Divider />
              <Flex w="100%" p={2} justifyContent="end">
                <Button
                  onClick={() => {
                    setIsPersonalizedCustomField(true);
                    createCustomFieldModal.onOpen();
                  }}
                  rightIcon={<Icon as={FaPlus} />}
                >
                  Create
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {createCustomFieldModal && (
        <AddCustomFieldModal
          isOpen={createCustomFieldModal.isOpen}
          onClose={createCustomFieldModal.onClose}
          newCustomFieldKey={newCustomFieldKey}
          setNewCustomFieldKey={setNewCustomFieldKey}
          isPersonalizedCustomField={isPersonalizedCustomField}
          addNewCustomField={addNewCustomField}
          onCustomFieldSelect={onCustomFieldSelect}
          customFields={customFields}
        />
      )}
    </>
  ) : (
    customFieldsButton
  );
};

export default AddCustomField;
