import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface AddCustomFieldModalProps {
  isOpen: boolean;
  onClose: () => void;
  newCustomFieldKey: string;
  setNewCustomFieldKey: (key: string) => void;
  isPersonalizedCustomField: boolean;
  addNewCustomField: (key: string, value: string) => void;
  onCustomFieldSelect: (cf: string) => void;
  customFields: { [key: string]: string };
}

const AddCustomFieldModal: React.FC<AddCustomFieldModalProps> = ({
  isOpen,
  onClose,
  newCustomFieldKey,
  setNewCustomFieldKey,
  isPersonalizedCustomField,
  addNewCustomField,
  onCustomFieldSelect,
  customFields,
}) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [newCustomFieldValue, setNewCustomFieldValue] = useState<string>("");
  const [newCustomFieldKeyError, setNewCustomFieldKeyError] =
    useState<string>("");

  useEffect(() => {
    if (!newCustomFieldKey) {
      setNewCustomFieldKeyError("");
      return;
    }

    if (
      customFields[newCustomFieldKey] !== undefined &&
      isPersonalizedCustomField === true
    ) {
      setNewCustomFieldKeyError("A custom field with this name already exists");
    } else {
      setNewCustomFieldKeyError("");
    }
  }, [newCustomFieldKey]);

  const resetCustomFieldForm = useCallback(() => {
    setNewCustomFieldKey("");
    setNewCustomFieldValue("");
    setNewCustomFieldKeyError("");
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Personalized Custom Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl isInvalid={!!newCustomFieldKeyError} isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="pet_name"
                value={newCustomFieldKey}
                onChange={(e) => setNewCustomFieldKey(e.target.value)}
                isDisabled={!isPersonalizedCustomField}
                autoFocus
              />
              <FormErrorMessage>{newCustomFieldKeyError}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Example</FormLabel>
              <Input
                placeholder="Doctor Dog"
                value={newCustomFieldValue}
                onChange={(e) => setNewCustomFieldValue(e.target.value)}
                autoFocus
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between">
          <Button
            colorScheme={colorScheme}
            variant="ghost"
            onClick={() => {
              onClose();
              resetCustomFieldForm();
            }}
          >
            Close
          </Button>
          <Button
            colorScheme={colorScheme}
            rightIcon={<ArrowForwardIcon />}
            onClick={() => {
              addNewCustomField(newCustomFieldKey, newCustomFieldValue);
              onCustomFieldSelect(`{${newCustomFieldKey}}`);
              resetCustomFieldForm();
              onClose();
            }}
            isDisabled={
              !newCustomFieldKey ||
              !newCustomFieldValue ||
              !!newCustomFieldKeyError
            }
          >
            Create & Use
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCustomFieldModal;
