import { Flex, useColorMode } from "@chakra-ui/react";
import { ReactComponent as HeadsetMan } from "assets/icons/call-center-man.svg";
import { ReactComponent as AIRobot } from "assets/icons/robot.svg";
import React from "react";
import { FaBroadcastTower } from "react-icons/fa";
import { IoStorefrontOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const AdminNavigation = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();

  return (
    <>
      <Link to={`/${merchant.id}/admin/merchants`}>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          direction="column"
          _hover={{
            background: colorMode === "dark" ? "gray.900" : "gray.50",
          }}
        >
          <IoStorefrontOutline size="4em" stroke="lightgray" /> <br />
          Merchants
        </Flex>
      </Link>
      <Link to={`/${merchant.id}/admin/agents`}>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          direction="column"
          _hover={{
            background: colorMode === "dark" ? "gray.900" : "gray.50",
          }}
        >
          <HeadsetMan style={{ height: "60px", width: "60px" }} />
          <br />
          Agents
        </Flex>
      </Link>
      <Link to={`/${merchant.id}/admin/ai`}>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          direction="column"
          _hover={{
            background: colorMode === "dark" ? "gray.900" : "gray.50",
          }}
          sx={{
            "> svg": {
              fill: colorMode === "dark" ? "white" : "gray.200",
            },
          }}
        >
          <AIRobot style={{ height: "60px", width: "60px" }} />
          <br />
          AI
        </Flex>
      </Link>
      <Link to={`/${merchant.id}/admin/broadcast`}>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          direction="column"
          _hover={{
            background: colorMode === "dark" ? "gray.900" : "gray.50",
          }}
          sx={{
            "> svg": {
              fill: colorMode === "dark" ? "white" : "gray.200",
            },
          }}
        >
          <FaBroadcastTower style={{ height: "60px", width: "60px" }} />
          <br />
          Broadcast
        </Flex>
      </Link>
    </>
  );
};

export default AdminNavigation;
