import TemplateDomain from "entities/domain/templates";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";

export default function useAvailableCustomFields(
  template: TemplateDomain | null = null
) {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { customFieldKeys } = useAppSelector((state) => state.group);

  const baseCustomFields: Record<string, string> = useMemo(
    () => ({
      contact_first_name: activeConversation?.customerFirstName || "",
      contact_last_name: activeConversation?.customerLastName || "",
      agent_first_name: currentAgent?.name || "",
      agent_last_name: currentAgent?.surname || "",
      agent_name:
        currentAgent?.name && currentAgent?.surname
          ? `${currentAgent.name} ${currentAgent.surname}`
          : "",
      agent_phone: currentAgent?.phone || "",
      agent_email: currentAgent?.email || "",
      merchant_name: merchant.name || "",
      ...(template?.customFields || {}),
    }),
    [
      activeConversation?.customerFirstName,
      activeConversation?.customerLastName,
      currentAgent?.name,
      currentAgent?.surname,
      currentAgent?.phone,
      currentAgent?.email,
      merchant.name,
      template?.customFields,
    ]
  );

  const [allFields, setAllFields] =
    useState<Record<string, string>>(baseCustomFields);

  useEffect(() => {
    const extendedCustomFields = customFieldKeys.reduce((fields, fieldKey) => {
      fields[fieldKey] = "";
      return fields;
    }, {} as Record<string, string>);

    setAllFields({
      ...extendedCustomFields,
      ...baseCustomFields,
    });
  }, [customFieldKeys, baseCustomFields]);

  return allFields;
}

export const customFieldRegExp = /{([a-z0-9]+(_[a-z0-9]*)*)+}/g;
