import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { MdArrowDropUp } from "react-icons/md";
import { useAppSelector } from "redux/hooks";

interface Action {
  text: "Save" | "Send" | "Schedule";
  requiresConfirmation: boolean;
  setShowConfirmation?: (show: boolean) => void;
  isConfirmed?: boolean;
  action: () => Promise<void>;
}

interface CampaignFormActionsBarProps {
  activeStep: number;
  completedSteps: number[];
  disabledSteps: number[];
  action: Action["text"];
  actions: Action[];
  setAction: (action: Action["text"]) => void;
  setStep: (step: number) => void;
  setCompletedSteps: (steps: number[]) => void;
  moveToNextStep: () => void;
}

const getColorByAction = (
  action: string
): {
  scheme: "green" | "purple" | "blue";
} => {
  switch (action) {
    case "Send":
      return {
        scheme: "green",
      };
    case "Schedule":
      return {
        scheme: "purple",
      };
    case "Save":
    default:
      return {
        scheme: "blue",
      };
  }
};

const CampaignFormActionsBar = ({
  activeStep,
  action,
  actions,
  disabledSteps,
  setAction,
  completedSteps,
  setStep,
  setCompletedSteps,
  moveToNextStep,
}: CampaignFormActionsBarProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  // We don't have a way to reset this when the user clicks on the action button
  // so we should just assume that any action that is propagated here should fully
  // unmount the component
  const wasClicked = useRef<boolean>(false);

  return !action || !actions.length ? null : (
    <Flex
      flexGrow={0}
      flexShrink={0}
      flexBasis="auto"
      justifyContent={activeStep === 0 ? "end" : "space-between"}
      alignItems="center"
      position="sticky"
      bottom={0}
      p={5}
      w="100%"
      zIndex={99}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
      borderTopStyle="solid"
      borderTopWidth="1px"
      borderTopColor={colorMode === "dark" ? "gray.700" : "gray.200"}
    >
      {activeStep > 0 && (
        <Button
          colorScheme={colorScheme}
          disabled={false}
          isLoading={false}
          onClick={() => {
            if (activeStep > 0) {
              setStep(activeStep - 1);
            }
          }}
          variant="outline"
          backgroundColor={colorMode === "dark" ? "gray.800" : "white"}
        >
          Back
        </Button>
      )}
      {activeStep < 3 ? (
        <Button
          colorScheme={colorScheme}
          isDisabled={disabledSteps.includes(activeStep)}
          isLoading={false}
          id="campaign-form-next-button"
          className={`campaign-form-finish-step-${activeStep}`}
          type="submit"
          onClick={() => {
            moveToNextStep();
          }}
          rounded="full"
        >
          Next
        </Button>
      ) : (
        <ButtonGroup
          isAttached
          variant="solid"
          colorScheme={
            activeStep === 3 ? getColorByAction(action).scheme : "blue"
          }
        >
          <Button
            colorScheme={
              activeStep === 3 ? getColorByAction(action).scheme : "blue"
            }
            isDisabled={disabledSteps.includes(activeStep)}
            isLoading={false}
            id="campaign-form-action-button"
            className={`campaign-form-finish-step-${activeStep}`}
            type="submit"
            onClick={() => {
              setCompletedSteps([...completedSteps, activeStep]);
              if (activeStep < 3) {
                setStep(activeStep + 1);
              } else {
                if (wasClicked.current) {
                  return;
                }
                wasClicked.current = true;
                actions
                  .find((a) => a.text === action)!
                  .action()
                  .finally(() => {
                    wasClicked.current = false;
                  });
              }
            }}
            rounded="full"
          >
            {activeStep === 3 ? action : "Next"}
          </Button>
          <Menu>
            <MenuButton aria-label="Choose another action menu button">
              <IconButton
                backgroundColor={`${
                  activeStep === 3
                    ? `${getColorByAction(action).scheme}.400`
                    : "blue"
                }.400`}
                borderLeftWidth="1px"
                borderColor="white"
                borderBottomStartRadius={0}
                borderTopStartRadius={0}
                aria-label="Choose another action"
                icon={<MdArrowDropUp />}
                rounded="full"
              />
            </MenuButton>
            <MenuList zIndex={5}>
              {actions.map((a) => (
                <MenuItem onClick={() => setAction(a.text)} aria-label={a.text}>
                  {a.text}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      )}
    </Flex>
  );
};

export default CampaignFormActionsBar;
