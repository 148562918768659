import PromptDomain, {
  PromptAction,
  PromptState,
  PromptType,
} from "entities/domain/prompt";
import { PromptDTO } from "entities/dto/PromptDTO";

export function promptTransformFromDtoToDomain(p: PromptDTO): PromptDomain {
  const state =
    p.status === "queued"
      ? PromptState.IN_PROGRESS
      : p.status === "completed"
      ? PromptState.COMPLETED
      : PromptState.FAILED;

  return new PromptDomain(
    p.id,
    p.conversation_id,
    p.type as PromptType,
    {
      body: p.content.body,
      actions: p.content.actions as PromptAction[],
    },
    p.reply_to_message_id,
    p.last_updated_at,
    state
  );
}
