import axios from "axios";
import { socketUrl } from "util/constants";
import {
  LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY,
  LocalStorage,
} from "util/localStorage";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:5004";

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "App-Version": process.env.REACT_APP_SENTRY_RELEASE || "undefined",
  },
});

const websocketServerInstance = axios.create({
  baseURL: socketUrl,
  headers: {
    "App-Version": process.env.REACT_APP_SENTRY_RELEASE || "undefined",
  },
});

websocketServerInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      const occurrencesCount = LocalStorage.upsert401Occurrences();

      window.location.href = `/logout?${LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY}=${occurrencesCount}`;
    }

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error && error.response && error.response.status === 401) {
      const occurrencesCount = LocalStorage.upsert401Occurrences();
      window.location.href = `/logout?${LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY}=${occurrencesCount}`;
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export { websocketServerInstance };

export default instance;
