export default class MerchantLocation {
  countryIso2: string;

  currencyIso3: string;

  currencySymbol: string;

  displayName: string;

  private constructor(
    countryIso2: string,
    currencyIso3: string,
    currencySymbol: string,
    displayName: string
  ) {
    this.countryIso2 = countryIso2;
    this.currencyIso3 = currencyIso3;
    this.currencySymbol = currencySymbol;
    this.displayName = displayName;
  }

  public static locations = [
    MerchantLocation.US(),
    MerchantLocation.UK(),
    MerchantLocation.IE(),
    MerchantLocation.CA(),
    MerchantLocation.AU(),
    MerchantLocation.BH(),
  ];

  private static US(): MerchantLocation {
    return new MerchantLocation("US", "usd", "$", "United States of America");
  }

  private static UK(): MerchantLocation {
    return new MerchantLocation("GB", "gbp", "£", "United Kingdom");
  }

  private static IE(): MerchantLocation {
    return new MerchantLocation("IE", "eur", "€", "Ireland");
  }

  private static CA(): MerchantLocation {
    return new MerchantLocation("CA", "cad", "$", "Canada");
  }

  private static AU(): MerchantLocation {
    return new MerchantLocation("AU", "aud", "$", "Australia");
  }

  private static BH(): MerchantLocation {
    return new MerchantLocation("BH", "bhd", "د.ب", "Bahrain");
  }

  public static from(countryIso2: string): MerchantLocation | undefined {
    const location = this.locations.filter(
      (l) => l.countryIso2 === countryIso2
    );
    if (this.locations.length === 0) {
      return undefined;
    }
    return location[0];
  }
}
