import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useRef,
  useState,
} from "react";
import Select, { SingleValue } from "react-select";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";

import { useAuth0 } from "@auth0/auth0-react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import MerchantGroup from "entities/domain/admin/merchants/merchant-group";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import SelectMerchant from "./select-merchant";
import SelectMerchantGroup from "./select-merchant-group";

const reader = new FileReader();

export interface MerchantCountryCodeOptionTypes {
  value: string;
  label: string;
}

export const merchantCountryCodeOptions: MerchantCountryCodeOptionTypes[] = [
  { value: "GB", label: "United Kingdom" },
  { value: "IE", label: "Ireland" },
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
  { value: "BH", label: "Bahrain" },
];

const AddMerchantForm = () => {
  const auth0Context = useAuth0();
  const { files } = useAppSelector((state) => state.attachments);
  const dispatch = useAppDispatch();
  const [merchantNameValue, setMerchantNameValue] = useState<string>("");
  const [countryCodeValue, setCountryCodeValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<MerchantCountryCodeOptionTypes>>(null);
  const [registeredCompanyIdValue, setRegisteredCompanyIdValue] =
    useState<string>("");
  const [googleReviewLink, setGoogleReviewLink] = useState<string>("");
  const [fbBusinessId, setFbBusinessId] = useState<string>("");
  const [googlePlaceIdValue, setGooglePlaceIdValue] = useState<string>("");
  const [googleAccountIdValue, setGoogleAccountIdValue] = useState<string>("");
  const [googleLocationIdValue, setGoogleLocationIdValue] =
    useState<string>("");
  const [stripeIdValue, setStripeIdValue] = useState<string>("");
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [selectedMerchantGroup, setSelectedMerchantGroup] =
    useState<MerchantGroup | null>(null);

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();

  const fileInput = useRef<HTMLInputElement>(null);

  const handleChangeMerchantName = (event: ChangeEvent<HTMLInputElement>) =>
    setMerchantNameValue(event.target.value);

  const handleChangeCompanyId = (event: ChangeEvent<HTMLInputElement>) =>
    setRegisteredCompanyIdValue(event.target.value);

  const handleChangeGoogleReviewLink = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleReviewLink(event.target.value);

  const handleChangFbBusinessIdValue = (event: ChangeEvent<HTMLInputElement>) =>
    setFbBusinessId(event.target.value);

  const handleChangePlaceId = (event: ChangeEvent<HTMLInputElement>) =>
    setGooglePlaceIdValue(event.target.value);

  const handleChangeGoogleAccountId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleAccountIdValue(event.target.value);

  const handleChangeGoogleLocationId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleLocationIdValue(event.target.value);

  const handleChangeStripeId = (event: ChangeEvent<HTMLInputElement>) =>
    setStripeIdValue(event.target.value);

  const handleChangeCountryCode = (
    e: SingleValue<MerchantCountryCodeOptionTypes>
  ) => {
    setCountryCodeValue(e!.value);
    setSelectedCountryCodeValue(e);
  };

  const resetValues = () => {
    setMerchantNameValue("");
    setCountryCodeValue("");
    setSelectedCountryCodeValue(null);
    setRegisteredCompanyIdValue("");
    setGoogleReviewLink("");
    setFbBusinessId("");
    setGooglePlaceIdValue("");
    setGoogleAccountIdValue("");
    setStripeIdValue("");
    setSelectedMerchantGroup(null);
    setSelectedMerchant(null);
    dispatch(clearAttachments());
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toast({
        status: "error",
        title: "An error occurred",
      });
    };
  };

  const isDisabled = (): boolean => !merchantNameValue || !countryCodeValue;

  const handleSaveMerchant = async () => {
    try {
      await AdminService.createMerchant(auth0Context, {
        name: merchantNameValue,
        country: countryCodeValue,
        registered_company_id: registeredCompanyIdValue,
        google_review_link: googleReviewLink,
        facebook_business_id: fbBusinessId,
        google_place_id: googlePlaceIdValue,
        google_account_id: googleAccountIdValue,
        google_location_id: googleLocationIdValue,
        stripe_account_id: stripeIdValue,
        logo: files[0],
        base_merchant_id: selectedMerchant?.id,
        group_id: selectedMerchantGroup?.id,
      });
      toast({
        status: "success",
        title: "Merchant Created Successfully",
      });
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Error creating merchant",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <>
      <div id="merchant-form-input">
        <Stack spacing={3}>
          <FormControl>
            <FormLabel>Select a Merchant Group</FormLabel>
            <small> Leave empty to create a new group </small>
            <SelectMerchantGroup
              selectedMerchantGroup={selectedMerchantGroup}
              setSelectedMerchantGroup={setSelectedMerchantGroup}
              isClearable={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Select a base Merchant</FormLabel>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
              isClearable={true}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel mb="0.5rem">Merchant Name</FormLabel>
            <Input
              value={merchantNameValue}
              onChange={handleChangeMerchantName}
              placeholder="Fuzey LTD"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel mb="0.5rem">Country</FormLabel>
            <Select
              placeholder="Select Country Code"
              onChange={handleChangeCountryCode}
              options={merchantCountryCodeOptions}
              value={selectedCountryCodeValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Registered Company ID </FormLabel>
            <Input
              value={registeredCompanyIdValue}
              onChange={handleChangeCompanyId}
              placeholder="0000000000"
            />
          </FormControl>
          <FormControl>
            <FormLabel> Google Review Link </FormLabel>
            <Input
              value={googleReviewLink}
              onChange={handleChangeGoogleReviewLink}
              placeholder="google.com/1231231"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem"> Facebook Business ID </FormLabel>
            <Input
              value={fbBusinessId}
              onChange={handleChangFbBusinessIdValue}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem"> Google Place ID </FormLabel>
            <Input
              value={googlePlaceIdValue}
              onChange={handleChangePlaceId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem"> Google Location ID </FormLabel>
            <Input
              value={googleLocationIdValue}
              onChange={handleChangeGoogleLocationId}
              placeholder="0000"
            />
          </FormControl>

          <FormControl>
            <FormLabel mb="0.5rem"> Google Account ID </FormLabel>
            <Input
              value={googleAccountIdValue}
              onChange={handleChangeGoogleAccountId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem"> Stripe ID </FormLabel>
            <Input
              value={stripeIdValue}
              onChange={handleChangeStripeId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem"> Merchant Logo </FormLabel>
            <input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept={"image/*"}
            />
          </FormControl>
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={handleSaveMerchant}
          isDisabled={isDisabled()}
          mb={30}
          mt={30}
          size={ButtonSize.LARGE}
        >
          {" "}
          Save Merchant
        </Button>
      </div>
    </>
  );
};

export default AddMerchantForm;
