import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { $getRoot, EditorState, LexicalEditor } from "lexical";
import React, {
  MutableRefObject,
  ReactNode,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import { useAppSelector } from "redux/hooks";
import { isIOSPlatform } from "util/methods";

import EditCustomFieldModal from "./EditCustomFieldModal";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import Nodes from "./nodes";
import AutoLinkPlugin from "./plugins/autolink";
import ClickableLinkPlugin from "./plugins/clickable-link";
import CustomFieldShortcutPlugin from "./plugins/custom-field-shortcut";
import CustomFieldTypeaheadPlugin from "./plugins/custom-field-typeahead";
import DefaultTextPlugin from "./plugins/default-text";
import FloatingTextFormatToolbarPlugin from "./plugins/floating-text-format-toolbar";
import IosFixPlugin from "./plugins/ios-fix";
import MarkdownShortcutPlugin from "./plugins/markdown-shortcut";
import { MaxLengthPlugin } from "./plugins/max-length";
import OnBlurPlugin from "./plugins/on-blur";
import PastingPlugin from "./plugins/pasting";
import RefPlugin from "./plugins/ref";
import SpeechToTextPlugin from "./plugins/speech-to-text";
import ToolbarPlugin from "./plugins/toolbar";
import TreatingCustomFieldPlugin from "./plugins/treating-custom-field";

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: unknown) {
  // eslint-disable-next-line
  console.error(error);
}

export enum EditorUltraPlugin {
  AUTOFOCUS = "autofocus",
  RICHTEXT = "richtext",
  MAXLENGTH = "maxlength",
  SPEECH_RECOGNITION = "speech_recognition",
  TOOLBAR = "toolbar",
  FLOATING_TOOLBAR = "floating_toolbar",
}

interface EditorUltraProps {
  isEditable?: boolean;
  isPrivate?: boolean;
  id?: string;
  maxLines?: number;
  channels?: ConversationChannel[];
  exceededText?: string;
  toolbarChannel?: ConversationChannel;
  maxHeight?: string;
  maxCharLength?: number;
  maxWidth?: string;
  showMore?: boolean;
  isScrollable?: boolean;
  backgroundColor?: string;
  fontColor?: string;
  placeholder?: ReactNode;
  onBlur?: (latestText: string) => void;
  defaultText?: {
    value: string;
  };
  editorReference?: MutableRefObject<LexicalEditor | undefined>;
  setText?: (t: string) => void;
  enabledPlugins?: EditorUltraPlugin[];
  customFields?: CustomFields | null;
  addOrReplaceCustomField?: (key: string, value: string) => void;
  highlightUnknownCustomFields?: boolean;
  isDisabled?: boolean;
  customFieldsAlwaysGreen?: boolean;
}

const Placeholder = forwardRef<
  HTMLParagraphElement,
  {
    text: React.ReactNode;
    isInputDisabled?: boolean;
    isToolbarShown?: boolean;
  }
>(({ text, isInputDisabled = false, isToolbarShown = false }, ref) =>
  typeof text === "string" ? (
    <Text
      color="gray.400"
      width="inherit"
      outline="none"
      userSelect="none"
      pointerEvents="none"
      display="inline-block"
      ref={ref}
      {...(!isInputDisabled
        ? {
            position: "absolute",
            top: 0,
            left: 0,
            marginTop: isToolbarShown ? "3.5rem" : 0,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }
        : {})}
    >
      {text}
    </Text>
  ) : (
    text
  )
);

const WHATSAPP_SUPPORTED_MARKDOWN = ["*", "**", "_", "`"];

const EditorUltra = ({
  placeholder = "Enter some text",
  isPrivate = false,
  maxHeight,
  maxLines,
  maxWidth,
  maxCharLength = 1600,
  channels = [],
  showMore = false,
  exceededText = "",
  isScrollable = true,
  isEditable = true,
  onBlur,
  defaultText = { value: "" },
  setText,
  highlightUnknownCustomFields = true,
  enabledPlugins = [],
  customFields = null,
  addOrReplaceCustomField = undefined,
  editorReference = undefined,
  isDisabled = false,
  toolbarChannel,
  fontColor = "inherit",
  backgroundColor,
  id = "editor-ultra",
  customFieldsAlwaysGreen,
}: EditorUltraProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { historyState } = useSharedHistoryContext();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLongText, setIsLongText] = useState(false);
  const [currentLength, setCurrentLength] = useState<number>(
    defaultText?.value.length || 0
  );
  const [isEditCustomFieldModalOpen, setIsEditCustomFieldModalOpen] =
    useState<boolean>(false);
  const [customFieldToEdit, setCustomFieldToEdit] = useState<{
    key: CustomFieldKey;
    setNewValue: (newValue: string) => void;
  } | null>(null);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLElement | undefined
  >();

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (typeof _floatingAnchorElem !== "undefined") {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onChange = (editorState: EditorState) => {
    if (isDisabled || typeof setText === "undefined") {
      return;
    }

    editorState.read(() => {
      const root = $getRoot();
      const isRichText = enabledPlugins.includes(EditorUltraPlugin.RICHTEXT);

      let newText = root.getTextContent();

      if (isRichText) {
        newText = $convertToMarkdownString(
          TRANSFORMERS.filter((transformer) => {
            if (transformer.type === "text-format") {
              return WHATSAPP_SUPPORTED_MARKDOWN.includes(transformer.tag);
            }

            return true;
          })
        );
      }

      if (isDisabled || typeof setText === "undefined") {
        return;
      }

      setCurrentLength(newText.length);
      setText(newText);
    });
  };

  const initialConfig = {
    namespace: "FuzeyEditorUltra",
    onError,
    nodes: Nodes,
    editable: isEditable,
    theme: {
      text: {
        strikethrough: "textStrikethrough",
      },
    },
  };

  const placeholderRef = useRef<HTMLParagraphElement>(null);

  const supportsFloatingToolbar =
    !isDisabled &&
    (!isBaseSize || isIOSPlatform()) &&
    enabledPlugins.includes(EditorUltraPlugin.FLOATING_TOOLBAR);

  useEffect(() => {
    if (typeof editorReference !== "undefined") {
      editorReference.current?.setEditable(isEditable);
    }
  }, [isEditable]);

  useEffect(() => {
    if (!floatingAnchorElem) {
      setIsLongText(false);
      setIsCollapsed(true);
      return;
    }

    const actualText = floatingAnchorElem.querySelector(
      "#editor-ultra-content-editable"
    );

    if (!actualText) {
      setIsLongText(false);
      setIsCollapsed(true);
      return;
    }

    setIsLongText(
      (actualText.scrollHeight as number) > (actualText.clientHeight as number)
    );
  }, [floatingAnchorElem?.clientHeight, floatingAnchorElem?.scrollHeight]);

  const openEditCustomFieldModal = useCallback(
    (key: string, setNewValue: (newValue: string) => void) => {
      setCustomFieldToEdit({
        key: key as CustomFieldKey,
        setNewValue,
      });
      setIsEditCustomFieldModalOpen(true);
    },
    [addOrReplaceCustomField]
  );

  return (
    <>
      <Flex
        id={id}
        tabIndex={-1}
        w="100%"
        position="relative"
        background={
          typeof backgroundColor === "undefined"
            ? colorMode === "dark"
              ? "gray.700"
              : "inherit"
            : backgroundColor
        }
        borderRadius={12}
        direction="column"
        pb={2}
        cursor={
          channels.length === 1 && channels[0] === ConversationChannel.WHATSAPP
            ? "default"
            : isDisabled
            ? "not-allowed"
            : "text"
        }
        color={fontColor}
        minHeight="2rem"
        outline="none!important"
        sx={{
          ".textStrikethrough": {
            textDecoration: "line-through",
          },
          ".editor-scroller": {
            width: "100%",
            height: "100%",
            outline: "none",
          },
          ".editor-ultra-content-editable": {
            outline: "none",
          },
          ".editor": {
            outline: "none",
          },
          "div[role='textbox']": {
            outline: "none",
          },
          "[data-lexical-editor='true']": {
            width: "100%",
            outline: "none",
          },
          a: {
            color: `${colorScheme}.300`,
            textDecoration: "underline",
            cursor: "pointer",
          },
          ul: {
            marginLeft: "1.5rem",
          },
          ol: {
            marginLeft: "1.5rem",
          },
          blockquote: {
            marginLeft: "1.5rem",
            borderLeft: "4px solid",
            borderColor: colorMode === "dark" ? "gray.600" : "gray.300",
            paddingLeft: "0.5rem",
          },
        }}
      >
        <LexicalComposer initialConfig={initialConfig}>
          <ToolbarPlugin
            isEnabled={enabledPlugins.includes(EditorUltraPlugin.TOOLBAR)}
            channel={toolbarChannel}
          />
          {enabledPlugins.includes(EditorUltraPlugin.RICHTEXT) ? (
            <>
              <RichTextPlugin
                contentEditable={
                  isDisabled && !defaultText.value ? (
                    <Box w="100%" />
                  ) : (
                    <div className="editor-scroller">
                      <div className="editor" ref={onRef}>
                        <ContentEditable
                          id="editor-ultra-content-editable"
                          data-private={isPrivate}
                          style={{
                            maxHeight:
                              showMore && isLongText && !isCollapsed
                                ? "initial"
                                : maxHeight,
                            maxWidth,
                            overflowY: isScrollable ? "auto" : "hidden",
                            ...(isScrollable === false &&
                            (typeof maxWidth !== "undefined" ||
                              (showMore && isCollapsed))
                              ? {
                                  textOverflow: "ellipsis",
                                }
                              : {}),
                            ...(typeof maxWidth !== "undefined"
                              ? {
                                  whiteSpace: "nowrap",
                                }
                              : {}),
                          }}
                        />
                      </div>
                    </div>
                  )
                }
                placeholder={
                  <Placeholder
                    ref={placeholderRef}
                    text={placeholder}
                    isInputDisabled={isDisabled}
                    isToolbarShown={enabledPlugins.includes(
                      EditorUltraPlugin.TOOLBAR
                    )}
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <ListPlugin />
              <MarkdownShortcutPlugin channels={channels} />
              {supportsFloatingToolbar && (
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                />
              )}
            </>
          ) : (
            <PlainTextPlugin
              contentEditable={
                isDisabled && !defaultText.value ? (
                  <Box w="100%" />
                ) : (
                  <ContentEditable
                    id="editor-ultra-content-editable"
                    data-private={isPrivate}
                    style={{
                      maxHeight:
                        showMore && !isCollapsed ? "initial" : maxHeight,
                      maxWidth,
                      overflowY: isScrollable ? "auto" : "hidden",
                      ...(isScrollable === false &&
                      (typeof maxWidth !== "undefined" ||
                        (showMore && isCollapsed))
                        ? {
                            textOverflow: "ellipsis",
                          }
                        : {}),
                      ...(typeof maxWidth !== "undefined"
                        ? {
                            whiteSpace: "nowrap",
                          }
                        : {}),
                    }}
                  />
                )
              }
              placeholder={
                <Placeholder ref={placeholderRef} text={placeholder} />
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
          )}
          <OnChangePlugin onChange={onChange} />
          <PastingPlugin />
          <OnBlurPlugin
            onBlur={onBlur}
            isRichText={enabledPlugins.includes(EditorUltraPlugin.RICHTEXT)}
          />
          <IosFixPlugin />
          <RefPlugin reference={editorReference} />
          <DefaultTextPlugin
            defaultText={defaultText}
            channels={channels}
            isRichText={enabledPlugins.includes(EditorUltraPlugin.RICHTEXT)}
          />
          <HistoryPlugin externalHistoryState={historyState} />
          {enabledPlugins.includes(EditorUltraPlugin.MAXLENGTH) ? (
            <MaxLengthPlugin
              maxLength={maxCharLength}
              currentLength={currentLength}
              channels={channels}
              exceededText={exceededText}
              maxLines={maxLines}
            />
          ) : (
            ""
          )}
          {customFields ? (
            <>
              <CustomFieldTypeaheadPlugin
                highlightUnknownCustomFields={highlightUnknownCustomFields}
                customFields={customFields}
                openEditCustomFieldModal={
                  addOrReplaceCustomField && customFields
                    ? openEditCustomFieldModal
                    : undefined
                }
              />
              <CustomFieldShortcutPlugin
                highlightUnknownCustomFields={highlightUnknownCustomFields}
                customFields={customFields}
                openEditCustomFieldModal={
                  addOrReplaceCustomField && customFields
                    ? openEditCustomFieldModal
                    : undefined
                }
                hasArtificialValue={
                  typeof customFieldsAlwaysGreen === "undefined"
                    ? undefined
                    : !customFieldsAlwaysGreen
                }
              />
              <TreatingCustomFieldPlugin />
              {addOrReplaceCustomField && customFieldToEdit && (
                <EditCustomFieldModal
                  addOrReplaceCustomField={addOrReplaceCustomField}
                  setIsOpen={setIsEditCustomFieldModalOpen}
                  isOpen={isEditCustomFieldModalOpen}
                  field={customFieldToEdit}
                  customFields={customFields}
                />
              )}
            </>
          ) : (
            ""
          )}
          <SpeechToTextPlugin />
          {toolbarChannel === ConversationChannel.WHATSAPP ? (
            ""
          ) : (
            <AutoLinkPlugin />
          )}
          <ClickableLinkPlugin newTab={true} />
          {enabledPlugins.includes(EditorUltraPlugin.AUTOFOCUS) ? (
            <AutoFocusPlugin defaultSelection="rootEnd" />
          ) : (
            ""
          )}
        </LexicalComposer>
        {isLongText && isCollapsed && showMore && (
          <Flex
            justifyContent="center"
            alignItems="center"
            w="100%"
            // position="absolute"
            // bottom={0}
          >
            <Button
              size="xs"
              colorScheme={colorScheme}
              w={isBaseSize ? "100%" : "auto"}
              onClick={() => setIsCollapsed(false)}
            >
              Show more
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default memo(EditorUltra);
