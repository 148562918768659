export enum PromptType {
  REPLY = "reply",
}

export enum PromptAction {
  REPLY = "reply",
  CLOSE_CONVERSATION = "close_conversation",
}

export enum PromptState {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export default class PromptDomain {
  id: string;

  conversationId: number;

  type: PromptType;

  body: string | undefined;

  actions: PromptAction[] | undefined;

  replyToMessageId: number | undefined;

  lastUpdatedAt: string;

  state: PromptState;

  public hasReplyAction(): boolean {
    return this.hasAction(PromptAction.REPLY);
  }

  public hasCloseConversationAction(): boolean {
    return this.hasAction(PromptAction.CLOSE_CONVERSATION);
  }

  public hasReplyAndCloseConversationActions(): boolean {
    return this.hasReplyAction() && this.hasCloseConversationAction();
  }

  private hasAction(action: PromptAction): boolean {
    return this.actions?.includes(action) ?? false;
  }

  constructor(
    id: string,
    conversationId: number,
    type: PromptType,
    content:
      | {
          body: string;
          actions: string[];
        }
      | undefined,
    replyToMessageId: number | undefined,
    lastUpdatedAt: string,
    state: PromptState
  ) {
    this.id = id;
    this.conversationId = conversationId;
    this.body = content?.body;
    this.actions = content?.actions as PromptAction[];
    this.type = type;
    this.replyToMessageId = replyToMessageId;
    this.lastUpdatedAt = lastUpdatedAt;
    this.state = state;
  }
}
