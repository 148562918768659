import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import AttributeDomain, {
  AttributeType,
} from "entities/domain/attributes/attribute-domain";

export interface CreateAttributePayload {
  name: string;
  type: AttributeType;
}

export interface UpdateAttributePayload {
  name: string;
}

export interface DeleteAttributeForbiddenResponseData {
  message: string;
  code: string;
  data: {
    attribute_id: string;
    customer_ids: number[];
  }[];
}

interface GroupState {
  loading: boolean;
  errors: any[];
  attributes: AttributeDomain[];
  customFieldKeys: string[];
}

const initialState: GroupState = {
  loading: false,
  errors: [],
  attributes: [],
  customFieldKeys: [],
};

const sortAttributes = (a: AttributeDomain, b: AttributeDomain) =>
  new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

const insertAttribute = (
  attribute: AttributeDomain,
  attributes: Draft<AttributeDomain>[]
): Draft<AttributeDomain>[] => {
  const attributeExists = !!attributes.find((a) => a.id === attribute.id);

  const newAttributes = attributeExists
    ? attributes.map((a) => (attribute?.id === a.id ? attribute : a))
    : [attribute, ...attributes].sort(sortAttributes);

  return newAttributes;
};

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    fetchAttributes: (state) => {
      state.loading = true;
    },
    fetchAttributesSuccess: (
      state,
      action: PayloadAction<AttributeDomain[]>
    ) => {
      state.loading = false;
      state.attributes = action.payload.sort(sortAttributes);
      state.errors = [];
    },
    createAttributeSuccess: (state, action: PayloadAction<AttributeDomain>) => {
      state.loading = false;
      state.attributes = insertAttribute(action.payload, state.attributes);
      state.errors = [];
    },
    updateAttributeSuccess: (state, action: PayloadAction<AttributeDomain>) => {
      state.loading = false;
      state.attributes = insertAttribute(action.payload, state.attributes);
      state.errors = [];
    },
    deleteAttributeSuccess: (state, action: PayloadAction<AttributeDomain>) => {
      state.loading = false;
      state.attributes = state.attributes.filter(
        (attribute) => attribute.id !== action.payload.id
      );
      state.errors = [];
    },
    setCustomFieldKeys: (state, action: PayloadAction<string[]>) => {
      state.customFieldKeys = action.payload;
    },
  },
});

export const {
  fetchAttributes,
  fetchAttributesSuccess,
  createAttributeSuccess,
  updateAttributeSuccess,
  deleteAttributeSuccess,
  setCustomFieldKeys,
} = groupSlice.actions;

export default groupSlice.reducer;
