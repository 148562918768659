import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Text,
  Tooltip,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { ReactComponent as HouseIcon } from "assets/icons/house-regular.svg";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import CustomerAddressDomain from "entities/domain/customers/customer-address-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import FuzeyDropdown from "../dropdown";
import ContactCardAccordionContent from "./ContactCardAccordionContent";

interface ContactDetailsProps {
  contactAddress?: CustomerAddressDomain;
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
}

const ContactAddressDetails = ({
  contactAddress,
  contactToDisplay,
  isExpanded,
}: ContactDetailsProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { editContact, getContactTags } = useContactsStore();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [address, setAddress] = useState<CustomerAddressDomain | undefined>();
  const [firstLine, setFirstLine] = React.useState<string>("");
  const [secondLine, setSecondLine] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [country, setCountry] = useState<string | undefined>("");
  const [postcode, setPostcode] = React.useState<string>("");
  const [state, setState] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    setAddress(contactAddress);
    setFirstLine(contactAddress?.firstLine || "");
    setSecondLine(contactAddress?.secondLine || "");
    setCity(contactAddress?.city || "");
    setCountry(contactAddress?.country || "");
    setPostcode(contactAddress?.postcode || "");
    setState(contactAddress?.state || undefined);
  }, [contactToDisplay]);

  const countryTypeOptions: { value: string; label: string }[] = [
    { value: "IE", label: "Ireland" },
    { value: "UK", label: "United Kingdom" },
    { value: "US", label: "United States of America" },
    { value: "BH", label: "Bahrain" },
  ];

  const onSaveInfo = async () => {
    if (!country || !firstLine || !secondLine || !city || !postcode) return;

    editContact({
      id: contactToDisplay!.id!,
      name: contactToDisplay.name || undefined,
      surname: contactToDisplay.surname || undefined,
      tags: getContactTags(contactToDisplay).map((tag: TagsDomain) => tag.tag),
      tagIds: contactToDisplay.tagIds,
      address: country
        ? {
            first_line: firstLine,
            second_line: secondLine,
            city,
            country,
            state,
            postcode,
          }
        : undefined,
      channels: contactToDisplay.channels.map((c: CustomerChannelDomain) => ({
        id: c.id,
        type: c.type,
        handle: c.handle,
        is_active: c.isActive,
        is_subscribed: c.isSubscribed,
      })),
      notes: contactToDisplay.notes,
    }).then((res) => {
      setAddress(res?.address);
    });
  };

  const isAddressSame: boolean =
    contactAddress?.firstLine === firstLine &&
    contactAddress?.secondLine === secondLine &&
    contactAddress?.city === city &&
    contactAddress?.country === country &&
    contactAddress?.postcode === postcode &&
    contactAddress?.state === state;

  const handleOnBlur = async (event: any) => {
    if (contactToDisplay.isArchived) {
      return;
    }

    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    if (!isAddressSame) {
      onSaveInfo();
    }

    setIsEditing(false);
  };

  return (
    <Box borderBottom="1px" borderColor="gray.50" pb={2}>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={HouseIcon}
            boxSize={6}
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ fill: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
        }
        sectionContent={
          <>
            {isEditing && (
              <Box onBlur={handleOnBlur} mx={4}>
                <FuzeyDropdown
                  placeholder="Select option"
                  setSelectedValue={(e) => setCountry(e)}
                  width="100%"
                  isMulti={false}
                  controlShouldRenderValue={true}
                  isClearable
                  closeMenuOnSelect={
                    true as ((() => void) & boolean) | undefined
                  }
                  isSetOnSelect
                  borderColor={`${colorScheme}.400`}
                  optionFontColor="var(--chakra-colors-gray-700)"
                  options={countryTypeOptions}
                  defaultValue={countryTypeOptions.filter(
                    (option) => option.value === country
                  )}
                  control={{ height: "40px" }}
                  id="contact-form-country"
                  autoFocus
                  zIndex={3}
                />
                {country && (
                  <>
                    <HStack mt={2}>
                      <Input
                        colorScheme={colorScheme}
                        value={firstLine}
                        onChange={(e) => setFirstLine(e.target.value)}
                        placeholder="House / flat no."
                        size="sm"
                        borderColor={
                          !firstLine ? "red.300" : `${colorScheme}.400`
                        }
                      />
                      <Input
                        colorScheme={colorScheme}
                        value={secondLine}
                        onChange={(e) => setSecondLine(e.target.value)}
                        placeholder="Street Name"
                        size="sm"
                        borderColor={
                          !secondLine ? "red.300" : `${colorScheme}.400`
                        }
                      />
                    </HStack>
                    <Input
                      colorScheme={colorScheme}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Town/City"
                      size="sm"
                      mt={2}
                      borderColor={!city ? "red.300" : `${colorScheme}.400`}
                    />
                    <Input
                      colorScheme={colorScheme}
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      placeholder="Postcode"
                      size="sm"
                      mt={2}
                      borderColor={!postcode ? "red.300" : `${colorScheme}.400`}
                    />
                    <Input
                      colorScheme={colorScheme}
                      display={country !== "US" ? "none" : ""}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      placeholder="State"
                      size="sm"
                      mt={2}
                      borderColor={!state ? "red.300" : `${colorScheme}.400`}
                    />
                  </>
                )}
              </Box>
            )}

            {address && !isEditing && (
              <Tooltip
                label="Click to edit address"
                px={3}
                py={1}
                backgroundColor="rgba(54, 178, 250, 0.9)"
                isDisabled={contactToDisplay.isArchived}
              >
                <VStack
                  spacing={0.5}
                  align="center"
                  mx={7}
                  pt={2}
                  onClick={() => {
                    if (contactToDisplay.isArchived) {
                      return;
                    }
                    setIsEditing(true);
                  }}
                  cursor={contactToDisplay.isArchived ? "default" : "pointer"}
                >
                  <Text fontSize="sm" fontWeight={400}>
                    {address.firstLine} {address.secondLine}
                  </Text>
                  <Text fontSize="sm" fontWeight={400}>
                    {" "}
                    {address.city}
                  </Text>
                  <HStack>
                    {address.state && (
                      <Text fontSize="sm" fontWeight={400}>
                        {" "}
                        {address.state}
                      </Text>
                    )}
                    <Text fontSize="sm" fontWeight={400}>
                      {address.postcode}
                    </Text>
                  </HStack>
                  <Text fontSize="sm" fontWeight={400}>
                    {address.displayCountryAbbreviateion()}
                  </Text>
                </VStack>
              </Tooltip>
            )}
            {!isEditing && !address && !contactToDisplay.isArchived && (
              <Flex justifyContent="center">
                <Button
                  colorScheme={colorScheme}
                  variant="ghost"
                  color="gray.400"
                  size="sm"
                  onClick={() => setIsEditing(true)}
                >
                  {" "}
                  Click here to add an address
                </Button>
              </Flex>
            )}
          </>
        }
      />
    </Box>
  );
};

export default ContactAddressDetails;
