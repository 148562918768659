import {
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import AddCustomField from "components/shared/AddCustomField";
import React, { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface ToolbarProps {
  isDisabled: boolean;
  customFields: { [key: string]: string };
  onEmojiSelect?: (emojiData: { native: string }) => void;
  onCustomFieldSelect?: (cf: string) => void;
  addNewCustomField: (key: string, value: string) => void;
}

const Toolbar = ({
  isDisabled,
  customFields,
  onEmojiSelect,
  onCustomFieldSelect,
  addNewCustomField,
}: ToolbarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);

  return (
    <HStack alignItems="center">
      {onCustomFieldSelect === undefined ? null : (
        <AddCustomField
          isDisabled={isDisabled}
          customFields={customFields}
          onCustomFieldSelect={onCustomFieldSelect}
          addNewCustomField={addNewCustomField}
          shouldAskForExampleValues={true}
        />
      )}
      {isBaseSize || onEmojiSelect === undefined ? null : (
        <Popover
          closeOnBlur={true}
          isOpen={openEmojiPopover}
          onClose={() => {
            setOpenEmojiPopover(false);
          }}
        >
          <Portal>
            <PopoverContent>
              <Picker
                data={data}
                theme={colorMode}
                onEmojiSelect={onEmojiSelect}
              />
            </PopoverContent>
          </Portal>
          <PopoverTrigger>
            <IconButton
              aria-label="Select Emoji"
              isDisabled={isDisabled}
              variant="unstyled"
              colorScheme={colorScheme}
              onClick={() => {
                setOpenEmojiPopover(!openEmojiPopover);
              }}
              display="flex"
              alignItems="center"
              icon={
                <Icon
                  as={EmojiButtonIcon}
                  __css={{
                    height: "2.1rem",
                    width: "2.1rem",
                    g: {
                      path: {
                        fill:
                          colorMode === "dark" ? "yellow.200" : "yellow.500",
                      },
                    },
                  }}
                />
              }
            />
          </PopoverTrigger>
        </Popover>
      )}
    </HStack>
  );
};

export default Toolbar;
