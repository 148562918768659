import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import { CalendarBookingDTO } from "entities/dto/CalendarDTO";

const calendarTransformFromDtoToDomain = (
  event: CalendarBookingDTO
): CalendarBookingDomain => {
  if (!event.start_at_utc && !event.start_at) {
    throw new Error("Either start_at_utc or start_at must be present or both");
  }
  if (!event.end_at_utc && !event.end_at) {
    throw new Error("Either end_at_utc or end_at must be present or both");
  }

  return new CalendarBookingDomain(
    event.booking_id,
    (event.start_at_utc || event.start_at) as string,
    (event.end_at_utc || event.end_at) as string,
    event.start_timezone,
    event.end_timezone,
    event.agent_ids,
    event.customer_id || 0,
    event.title,
    event.description,
    event.notify_channel_selected
  );
};

export default calendarTransformFromDtoToDomain;
