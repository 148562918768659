import { useAuth0 } from "@auth0/auth0-react";
import { useBreakpointValue } from "@chakra-ui/media-query";
import SwitchAccount from "components/switch-account";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { setCurrentAgent } from "redux/features/agents";
import {
  setIsSelectorOpened,
  updateMerchantSuccess,
} from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import MerchantService from "services/merchant";
import PrivateRoute from "./PrivateRoute";

const MerchantPrivateRoute = () => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const Url = useLocation();
  const { merchantId } = useParams();
  const dispatch = useAppDispatch();

  const auth0Context = useAuth0();
  const { isAuthenticated } = auth0Context;
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { isSelectorOpened } = useAppSelector((state) => state.merchant);

  const getCurrentMerchant = async (
    id: number
  ): Promise<MerchantDomainBase | null> => {
    try {
      const merchantResponse = await MerchantService.getMerchant(
        auth0Context,
        id
      );

      return merchantResponse;
    } catch (err) {
      // eslint-disable-next-line
      console.error("Error fetching merchant", err);
    }

    return null;
  };

  useEffect(() => {
    if (Url.pathname.includes("public")) {
      return;
    }

    AgentsService.getCurrentAgent(auth0Context)
      .then((agent) => {
        if (!agent) {
          return;
        }

        dispatch(setCurrentAgent(agent));

        const availableMerchants = agent.merchants.map((m) => m.id);
        const selectedMerchantId = localStorage.getItem("fuzey:merchant:id");

        const merchantFromUrlAvailable = merchantId
          ? availableMerchants.includes(parseInt(merchantId, 10))
          : false;
        const merchantFromLocalStorageAvailable = selectedMerchantId
          ? availableMerchants.includes(parseInt(selectedMerchantId, 10))
          : false;

        if (merchantFromUrlAvailable) {
          getCurrentMerchant(parseInt(merchantId!, 10)).then(
            (merchantResponse) => {
              if (merchantResponse) {
                dispatch(updateMerchantSuccess(merchantResponse));
                localStorage.setItem("fuzey:merchant:id", merchantId!);
              } else {
                auth0Context.logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }
            }
          );

          return;
        } else {
          if (merchantFromLocalStorageAvailable) {
            window.location.href = `/${selectedMerchantId}${Url.pathname}${Url.search}`;

            return;
          }

          if (agent.merchants.length === 1) {
            const newSelectedMerchant = agent.merchants[0];
            localStorage.setItem(
              "fuzey:merchant:id",
              newSelectedMerchant.id.toString()
            );
            window.location.href = `/${newSelectedMerchant.id}${Url.pathname}${Url.search}`;

            return;
          }

          dispatch(setIsSelectorOpened(true));

          return;
        }
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          auth0Context.logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
      });
  }, [isAuthenticated]);

  return (
    <>
      {isSelectorOpened && (!merchant || isBaseSize) ? null : (
        <PrivateRoute>
          <Outlet />
        </PrivateRoute>
      )}
      {!merchant || isBaseSize ? (
        <SwitchAccount
          isRequired={true}
          merchants={currentAgent?.merchants || []}
        />
      ) : null}
    </>
  );
};

export default MerchantPrivateRoute;
