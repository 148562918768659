import { ColorModeScript } from "@chakra-ui/color-mode";
import Auth0ProviderWithHistory from "auth0ProviderWithHistory";
import Auth0Wrapper from "auth0Wrapper";
import LogRocket from "logrocket";
import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import FuzeyTheme from "theme";
import App from "./App";
import ErrorFallback from "./ErrorFallback";
import "./index.css";
import Store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  LogRocket.init("p8awhu/fuzey-production", {
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Auth0ProviderWithHistory>
        <Auth0Wrapper>
          <ColorModeScript
            initialColorMode={FuzeyTheme.config.initialColorMode}
          />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error) => {
              // eslint-disable-next-line
              console.error(
                "Global App Failure",
                error,
                window.location.href,
                localStorage.getItem("fuzey:merchant:id") || "undefined"
              );
            }}
          >
            <App />
          </ErrorBoundary>
        </Auth0Wrapper>
      </Auth0ProviderWithHistory>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
