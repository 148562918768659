import { format } from "date-fns";
import { updateFormatAccordingToCountry } from "util/methods";
import {
  Condition,
  ConditionType,
  Criteria,
  LogicalOperation,
  LogicalOperationType,
} from "../criteria";

export interface AudienceCriteria {
  operation: LogicalOperationType;
  conditions: Array<{
    operation: LogicalOperationType;
    conditions: Array<Condition>;
  }>;
}

function transformCriteriaToAudienceCriteria(
  criteria: Criteria
): AudienceCriteria {
  // Ensure criteria is not null or an array (which is not expected in the single Criteria interface).
  if (!criteria || Array.isArray(criteria)) {
    throw new Error("Invalid criteria format.");
  }

  const convertToNormalizedForm = (
    criteriaToConvert: Condition | LogicalOperation
  ): AudienceCriteria => {
    // For a single condition, wrap it in an OR, then in an AND.
    if ("type" in criteriaToConvert) {
      return {
        operation: LogicalOperationType.AND,
        conditions: [
          {
            operation: LogicalOperationType.OR,
            conditions: [criteriaToConvert],
          },
        ],
      };
    }

    // For an operation, ensure all conditions are operations, then return as is.
    if ("operation" in criteriaToConvert) {
      const everyConditionIsOperation = criteriaToConvert.conditions.every(
        (c) => "operation" in c
      );

      if (!everyConditionIsOperation) {
        // If not all conditions are operations, check if they are conditions.
        const everyConditionIsCondition = criteriaToConvert.conditions.every(
          (c) => "type" in c
        );

        if (!everyConditionIsCondition) {
          throw new Error(
            "All conditions under operation should be conditions."
          );
        }

        return {
          operation: LogicalOperationType.AND,
          conditions: [criteriaToConvert] as Array<{
            operation: LogicalOperationType;
            conditions: Array<Condition>;
          }>,
        } as AudienceCriteria;
      }

      // If all conditions are operations, check if they are in the correct format.
      for (const operation of criteriaToConvert.conditions) {
        const everyConditionIsCondition = (
          operation as LogicalOperation
        ).conditions.every((c) => "type" in c);

        if (!everyConditionIsCondition) {
          throw new Error(
            "All conditions under operation should be conditions."
          );
        }
      }

      return criteriaToConvert as AudienceCriteria;
    }

    throw new Error("Criteria format is not recognized.");
  };

  return convertToNormalizedForm(criteria);
}

export default class AudienceDomain {
  id: string | null;

  name: string;

  merchantId: number;

  count: number;

  criteria: AudienceCriteria | null;

  constructor(
    id: string | null,
    name: string,
    merchantId: number,
    count: number,
    criteria: Criteria | null
  ) {
    this.id = id;
    this.name = name;
    this.merchantId = merchantId;
    this.count = count;
    this.criteria = {
      operation: LogicalOperationType.AND,
      conditions: [
        {
          operation: LogicalOperationType.OR,
          conditions: [],
        },
      ],
    };

    if (!criteria) {
      return;
    }

    try {
      this.criteria = transformCriteriaToAudienceCriteria(criteria);
    } catch (error) {
      // eslint-disable-next-line
      console.error("Failed to transform criteria to audience criteria", error);
      this.criteria = null;
    }
  }

  public static generateName(): string {
    return `Import from ${format(
      new Date(),
      updateFormatAccordingToCountry("dd/MM/yyyy HH:mm:ss")
    )}`;
  }

  public static generateUploadTag(): string {
    return `uploaded_${format(
      new Date(),
      updateFormatAccordingToCountry("dd_MM_yyyy_HH_mm_ss")
    )}`;
  }

  addIncludedTagId(tagId: string): void {
    this.addTagId(tagId, ConditionType.CONTAINS);
  }

  addExcludedTagId(tagId: string): void {
    this.addTagId(tagId, ConditionType.NOT_CONTAINS);
  }

  removeIncludedTagId(tagId: string): void {
    this.removeTagId(tagId, ConditionType.CONTAINS);
  }

  removeExcludedTagId(tag: string): void {
    this.removeTagId(tag, ConditionType.NOT_CONTAINS);
  }

  private addTagId(tagId: string, type: ConditionType): void {
    if (type === ConditionType.CONTAINS) {
      this.criteria!.conditions[0].conditions.push({
        type,
        key: "tag_id",
        value: tagId,
      });
    } else if (type === ConditionType.NOT_CONTAINS) {
      this.criteria!.conditions[1].conditions.push({
        type,
        key: "tag_id",
        value: tagId,
      });
    }
  }

  private removeTagId(tagId: string, type: ConditionType): void {
    this.criteria = this.criteria!.conditions.reduce((acc, group, index) => {
      const conditions = group.conditions.filter(
        (c) => !("type" in c && c.type === type && c.value === tagId)
      );

      acc!.conditions[index].conditions = conditions;

      return acc;
    }, this.criteria);
  }
}
