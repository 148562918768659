import { CloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ReactComponent as ContactAttributeIcon } from "assets/icons/customer-report-line-svgrepo-com.svg";
import CreateContactAttribute from "components/user-settings/ContactAttributes/create-contact-attribute";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import VehicleDomain from "entities/domain/vehicle";
import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { insertContact } from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { canDeleteContacts } from "util/permissions";
import ConfirmationDialog from "../ConfirmationDialog";
import AllAttachments from "./AllAttachments";
import ChannelsArea from "./ChannelsArea";
import ContactAddressDetails from "./ContactAddressDetails";
import ContactCardAccordionContent from "./ContactCardAccordionContent";
import ContactNotes from "./ContactNotes";
import ContactTagsArea from "./ContactTagsArea";
import CustomerAttributeAccordionForm from "./CustomerAttributesAccordionContent";
import ProfileArea from "./ProfileArea";
import VehicleInfoArea from "./VehicleInfoArea";

interface ContactDetailsInterface {
  contactToDisplay?: ContactDomain;
  onClose?: () => void;
  handleCloseContactDisplay?: () => void;
  onCreateNewTag?: () => void;
  setTagIdToBeDeleted?: (val: string) => void;
  onChannelClick?: (chanId: string) => void;
  mobileMarginX?: string;
  showDeleteButton?: boolean;
  setDisplayBackIcon?: (val: boolean) => void;
  setDisplayMoreIcon?: (val: boolean) => void;
  handleOpenEditModal?: (val: ContactDomain) => void;
  handleOpenMergeModal?: (val: ContactDomain) => void;
  displayPopover?: boolean;
}

const ContactDetails = (props: ContactDetailsInterface) => {
  const context = useOutletContext<ContactDetailsInterface>();

  let contactToDisplay: ContactDomain | undefined;
  let onChannelClick: ((chanId: string) => void) | undefined;
  let handleCloseContactDisplay: (() => void) | undefined;
  let showDeleteButton: boolean | undefined;
  let mobileMarginX: string | undefined;
  let setDisplayBackIcon: ((b: boolean) => void) | undefined;
  let setDisplayMoreIcon: ((m: boolean) => void) | undefined;
  let handleOpenEditModal: ((c: ContactDomain) => void) | undefined;
  let handleOpenMergeModal: ((c: ContactDomain) => void) | undefined;
  let displayPopover: boolean | undefined;
  let onCreateNewTag: (() => void) | undefined;
  let setTagIdToBeDeleted: ((t: string) => void) | undefined;
  let onClose: (() => void) | undefined;

  if (context && Object.keys(context).length) {
    contactToDisplay = context.contactToDisplay;
    onChannelClick = context.onChannelClick;
    handleCloseContactDisplay = context.handleCloseContactDisplay;
    showDeleteButton = context.showDeleteButton;
    mobileMarginX = context.mobileMarginX;
    setDisplayBackIcon = context.setDisplayBackIcon;
    setDisplayMoreIcon = context.setDisplayMoreIcon;
    handleOpenEditModal = context.handleOpenEditModal;
    handleOpenMergeModal = context.handleOpenMergeModal;
    displayPopover = context.displayPopover;
    onCreateNewTag = context.onCreateNewTag;
    setTagIdToBeDeleted = context.setTagIdToBeDeleted;
    onClose = context.onClose;
  } else if (Object.keys(props).length) {
    contactToDisplay = props.contactToDisplay;
    onChannelClick = props.onChannelClick;
    handleCloseContactDisplay = props.handleCloseContactDisplay;
    showDeleteButton = props.showDeleteButton;
    mobileMarginX = props.mobileMarginX;
    setDisplayBackIcon = props.setDisplayBackIcon;
    setDisplayMoreIcon = props.setDisplayMoreIcon;
    handleOpenEditModal = props.handleOpenEditModal;
    handleOpenMergeModal = props.handleOpenMergeModal;
    displayPopover = props.displayPopover;
    onCreateNewTag = props.onCreateNewTag;
    setTagIdToBeDeleted = props.setTagIdToBeDeleted;
    onClose = props.onClose;
  } else {
    return null;
  }

  if (
    !(
      contactToDisplay &&
      onClose &&
      handleCloseContactDisplay &&
      onCreateNewTag &&
      setTagIdToBeDeleted
    )
  ) {
    return null;
  }

  const dispatch = useAppDispatch();

  // If opened contact isn't in the first page of the contacts list, it won't be in Redux store
  // We need it there to be able to track changes to it
  useEffect(() => {
    if (!contactToDisplay) {
      return;
    }
    dispatch(insertContact(contactToDisplay));
  }, [contactToDisplay]);

  const { search } = useLocation();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { deleteContact } = useContactsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();
  const attributesModal = useDisclosure();

  const [hasAttachments, setHasAttachments] = useState<boolean>(false);
  const [hasVehicles, setHasVehicles] = useState<boolean>(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { attributes } = useAppSelector((state) => state.group);

  useEffect(() => {
    if (setDisplayBackIcon) setDisplayBackIcon(true);
    if (setDisplayMoreIcon) setDisplayMoreIcon(true);
  }, []);

  const { colorScheme } = useAppSelector((state) => state.theme);

  const content = (
    <>
      <Box
        bgColor={
          colorMode === "dark"
            ? isBaseSize
              ? "gray.800"
              : "gray.700"
            : "white"
        }
        display="flex"
        flexDirection="column"
        mx={isBaseSize ? mobileMarginX : "initial"}
        overflowX="hidden"
      >
        <Box height="auto">
          <ProfileArea contactToDisplay={contactToDisplay} />
        </Box>
        <Box flex="1" overflowY="auto">
          <ChannelsArea
            contactToDisplay={contactToDisplay}
            onChannelClick={onChannelClick}
            merchant={merchant}
          />
          <Accordion allowToggle>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <Box borderBottom="1px" borderColor="gray.50" pb={2}>
                  <ContactCardAccordionContent
                    isExpanded={isExpanded}
                    sectionHeader={
                      <Icon
                        as={ContactAttributeIcon}
                        mr={2}
                        width="1.25rem"
                        height="1.25rem"
                        __css={{
                          path: {
                            fill:
                              colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.900`,
                            _hover:
                              colorMode === "dark" ? "gray.600" : "gray.300",
                          },
                        }}
                      />
                    }
                    sectionContent={
                      <VStack
                        w="100%"
                        h="100%"
                        alignItems="start"
                        justifyContent="start"
                        spacing={4}
                      >
                        {attributes.map((attr) => (
                          <CustomerAttributeAccordionForm
                            key={attr.id}
                            customerId={contactToDisplay!.id!}
                            attributeId={attr.id}
                            attributeName={attr.name}
                            attributeType={attr.type}
                          />
                        ))}
                        <Button
                          colorScheme={colorScheme}
                          gridGap={2}
                          mt={4}
                          size="sm"
                          alignSelf="center"
                          onClick={() => attributesModal.onOpen()}
                        >
                          <Icon
                            as={FaPlus}
                            fill={
                              colorMode === "dark"
                                ? `${colorScheme}.800`
                                : "white"
                            }
                          />
                          <Text>Add Attributes</Text>
                        </Button>
                        <CreateContactAttribute
                          isOpen={attributesModal.isOpen}
                          onClose={attributesModal.onClose}
                        />
                      </VStack>
                    }
                  />
                </Box>
              )}
            </AccordionItem>
            <AccordionItem border="none" isDisabled={!hasAttachments}>
              {({ isExpanded }) => (
                <AllAttachments
                  contactToDisplay={contactToDisplay!}
                  isExpanded={isExpanded}
                  onFirstLoad={(attachments: AttachmentDomain[]) => {
                    setHasAttachments(attachments.length > 0);
                  }}
                />
              )}
            </AccordionItem>
            {merchant.isIntegrationEnabled(IntegrationName.KEYLOOP) ? (
              <AccordionItem border="none" isDisabled={!hasVehicles}>
                {({ isExpanded }) => (
                  <VehicleInfoArea
                    contactToDisplay={contactToDisplay!}
                    isExpanded={isExpanded}
                    onFirstLoad={(vehicles: VehicleDomain[]) => {
                      setHasVehicles(vehicles.length > 0);
                    }}
                  />
                )}
              </AccordionItem>
            ) : null}
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <ContactTagsArea
                  contactTagIds={contactToDisplay?.tagIds || []}
                  contactId={contactToDisplay!.id!}
                  onCreateNewTag={onCreateNewTag!}
                  setTagIdToBeDeleted={setTagIdToBeDeleted!}
                  isExpanded={isExpanded}
                  isArchived={contactToDisplay!.isArchived}
                />
              )}
            </AccordionItem>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <ContactAddressDetails
                  contactAddress={contactToDisplay!.address}
                  contactToDisplay={contactToDisplay!}
                  isExpanded={isExpanded}
                />
              )}
            </AccordionItem>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <ContactNotes
                  contactToDisplay={contactToDisplay!}
                  isExpanded={isExpanded}
                />
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </>
  );

  const DesktopContent = (
    <>
      <Drawer
        onClose={handleCloseContactDisplay}
        isOpen={!!contactToDisplay}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent borderRadius="0 !important" px={0}>
          <Flex justify="space-between" p={4} alignItems="center">
            {displayPopover && (
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="More actions"
                    variant="ghost"
                    colorScheme="gray"
                  >
                    <Icon as={FiMoreHorizontal} />
                  </IconButton>
                </PopoverTrigger>

                <PopoverContent
                  _focus={{ outline: "none" }}
                  boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
                  w="170px"
                  borderRadius="12px"
                  style={{
                    transition: "all 0.1s",
                    outline: "none",
                  }}
                >
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() =>
                        navigate({
                          pathname: `/${merchant.id}/contacts/edit/${contactToDisplay?.id}`,
                          search: createSearchParams(search).toString(),
                        })
                      }
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderTopRadius: "12px",
                      }}
                    >
                      Edit Contact
                    </Flex>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() => {
                        if (handleOpenMergeModal)
                          handleOpenMergeModal(contactToDisplay!);
                      }}
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderBottomRadius:
                          showDeleteButton &&
                          canDeleteContacts(merchant.id, currentAgent!)
                            ? "0px"
                            : "12px",
                      }}
                    >
                      Merge Contact
                    </Flex>
                    {showDeleteButton &&
                      canDeleteContacts(merchant.id, currentAgent!) && (
                        <Flex
                          bg="transparent"
                          w="100%"
                          p={4}
                          cursor="pointer"
                          onClick={() => {
                            setIsDeleteDialogOpen(true);
                          }}
                          _hover={{
                            background:
                              colorMode === "dark" ? "gray.900" : "gray.50",
                            borderBottomRadius: "12px",
                          }}
                        >
                          Delete Contact
                        </Flex>
                      )}
                  </PopoverBody>
                </PopoverContent>
                <ConfirmationDialog
                  headerText="Delete Contact?"
                  messageText="Are you sure you want to delete this contact?"
                  cancelButtonText="No"
                  buttonText="Yes"
                  isDangerous={true}
                  isOpen={isDeleteDialogOpen}
                  setIsOpen={setIsDeleteDialogOpen}
                  confirmationCallback={() => {
                    if (!contactToDisplay) {
                      return;
                    }

                    deleteContact(contactToDisplay.id!);
                    handleCloseContactDisplay!();
                  }}
                />
              </Popover>
            )}

            <CloseIcon
              onClick={handleCloseContactDisplay}
              boxSize={3}
              cursor="pointer"
            />
          </Flex>

          <DrawerBody px={0}>{content}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );

  return isBaseSize ? (
    <Box w="100%" h="100%" display="flex" flexDirection="column">
      {content}
    </Box>
  ) : (
    DesktopContent
  );
};

export default ContactDetails;
