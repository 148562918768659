import {
  Badge,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import TemplateDomain from "entities/domain/templates";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import { useScrollWithShadow } from "hooks/use-scroll-with-shadows";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  MessageInputTab,
  updateMessageInputActiveTab,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import MessagePreview from "./MessagePreview";

interface ContentProps {
  children: React.ReactNode;
  topRightElement?: React.ReactNode;
  showHeader?: boolean;
  isEditable?: boolean;
  scrollHelper?: React.ReactNode;
}

export const getScrollShadowStyles = (
  colorMode: string,
  template: boolean,
  showHeader: boolean
) => {
  if (template || showHeader) {
    return {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: "-0.5rem",
      width: "100%",
      height: "0.5rem",
      background: `linear-gradient(to bottom, ${
        colorMode === "dark"
          ? "var(--chakra-colors-blackAlpha-100)"
          : "var(--chakra-colors-gray-50)"
      }, transparent)`,
    };
  }

  return {
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "0.5rem",
    background: `linear-gradient(to bottom, ${
      colorMode === "dark"
        ? "var(--chakra-colors-blackAlpha-100)"
        : "var(--chakra-colors-gray-50)"
    }, transparent)`,
  };
};

const Content: React.FC<ContentProps> = ({
  scrollHelper,
  children,
  topRightElement,
  showHeader = false,
  isEditable = true,
}) => {
  const {
    messageInput: { template, activeTab, text },
  } = useAppSelector((state) => state.conversations);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();

  const scrollShadowTopStyles = useMemo(() => {
    return getScrollShadowStyles(colorMode, !!template, showHeader);
  }, [colorMode, template, showHeader]);

  const availableCustomFields = useAvailableCustomFields(template);

  const scrollShadowBottomStyles = useMemo(() => {
    return {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "0.5rem",
      background: `linear-gradient(to top, ${
        colorMode === "dark"
          ? "var(--chakra-colors-blackAlpha-100)"
          : "var(--chakra-colors-gray-50)"
      }, transparent)`,
    };
  }, [colorMode]);

  const [tabIndex, setTabIndex] = useState<MessageInputTab>(
    template ? MessageInputTab.PREVIEW : MessageInputTab.EDIT
  );

  useEffect(() => {
    const newTabIndex = template
      ? MessageInputTab.PREVIEW
      : MessageInputTab.EDIT;

    setTabIndex(newTabIndex);
    dispatch(updateMessageInputActiveTab(newTabIndex));
  }, [template?.id]);

  const missingCustomFieldsTotal = useMemo(() => {
    if (!template) {
      return 0;
    }

    return TemplateDomain.getCustomFieldsWithoutValue(
      text,
      availableCustomFields
    ).length;
  }, [text, availableCustomFields]);

  useEffect(() => {
    if (activeTab === tabIndex) {
      return;
    }

    setTabIndex(activeTab);
  }, [activeTab]);

  const { scrollHelper: previewScrollHelper, onScrollHandler } =
    useScrollWithShadow();

  return (
    <VStack
      overflowY="auto"
      data-scroll-lock-scrollable
      id="message-input-content"
      w="100%"
      h="100%"
      position="relative"
      spacing={0}
      _before={
        !template && !showHeader && !scrollHelper ? scrollShadowTopStyles : {}
      }
      _after={!scrollHelper ? scrollShadowBottomStyles : {}}
      lineHeight="1.25rem"
    >
      {!template && !showHeader ? (
        <>
          {scrollHelper}
          {children}
        </>
      ) : (
        <Tabs
          variant="soft-rounded"
          size="sm"
          colorScheme="gray"
          w="100%"
          h="100%"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          index={tabIndex}
          onChange={(index: number) => {
            setTabIndex(index);
            dispatch(updateMessageInputActiveTab(index));
          }}
        >
          <TabList py={2} position="relative" _after={scrollShadowTopStyles}>
            <Tab ml={2} isDisabled={!isEditable} data-testid="message-edit-tab">
              Edit
            </Tab>
            <Tab mr={2} data-testid="message-preview-tab">
              Preview
              {missingCustomFieldsTotal > 0 ? (
                <Badge colorScheme="red" variant="subtle" size="xs" ml={1}>
                  {missingCustomFieldsTotal}
                </Badge>
              ) : null}
            </Tab>
            {topRightElement}
          </TabList>
          <TabPanels display="flex" h="100%" w="100%" overflow="hidden">
            <TabPanel
              display="flex"
              flexDirection="column"
              w="100%"
              h="100%"
              overflow="hidden"
              p={0}
              position="relative"
            >
              {scrollHelper}
              {children}
            </TabPanel>
            <TabPanel
              display="flex"
              flexDirection="column"
              w="100%"
              h="100%"
              overflow="hidden"
              p={0}
              position="relative"
            >
              {isBaseSize ? previewScrollHelper : null}
              <MessagePreview
                onScrollHandler={isBaseSize ? onScrollHandler : undefined}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export default memo(Content);
