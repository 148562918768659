import { DeleteIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  WhatsappButton,
  WhatsappButtonType,
} from "entities/domain/whatsapp-template";
import AddCustomField from "components/shared/AddCustomField";
import { customFieldRegExp } from "hooks/use-available-custom-fields";
import { isValidUrl } from "./utils";

interface ButtonInputProps {
  button: WhatsappButton;
  index: number;
  handleButtonChange: (index: number, field: string, value: string) => void;
  handleRemoveButton: (index: number) => void;
  isDisabled: boolean;
  customFields: { [key: string]: string };
  addNewCustomField: (key: string, value: string) => void;
}

const MAX_BUTTON_TEXT_LENGTH = 25;
const MAX_URL_LENGTH = 2000;
const MAX_PHONE_LENGTH = 20;

const ButtonInput = ({
  button,
  index,
  handleButtonChange,
  handleRemoveButton,
  isDisabled,
  customFields,
  addNewCustomField,
}: ButtonInputProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const [urlError, setUrlError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [textError, setTextError] = useState<string>("");

  const [isCustomFieldsDisabled, setIsCustomFieldsDisabled] =
    useState<boolean>(isDisabled);

  useEffect(() => {
    let shouldDisableCustomFields = isDisabled;
    let errorMessage = "";

    if (!button.url) {
      errorMessage = "URL is required";
    } else if (!isValidUrl(button.url.replace(customFieldRegExp, ""))) {
      errorMessage = "Invalid URL";
    } else if (button.url.length > MAX_URL_LENGTH) {
      errorMessage = "URL is too long";
    }

    const customFieldsInURL = button.url
      ?.match(customFieldRegExp)
      ?.filter((cf) => customFields[cf.replace(/[{}]/g, "")]);
    const numberOfCustomFields = customFieldsInURL?.length || 0;

    if (numberOfCustomFields === 1) {
      if (!button.url?.endsWith(customFieldsInURL![0])) {
        errorMessage = "Custom field must be at the end of the URL";
      }
      shouldDisableCustomFields = true;
    }

    if (numberOfCustomFields > 1) {
      errorMessage = "Only one custom field is allowed";
      shouldDisableCustomFields = true;
    }

    setIsCustomFieldsDisabled(shouldDisableCustomFields);
    setUrlError(errorMessage);
  }, [button.url, customFields, isDisabled]);

  useEffect(() => {
    if (!button.phoneNumber) {
      setPhoneNumberError("Phone number is required");
    } else if (!isValidPhoneNumber(button.phoneNumber)) {
      setPhoneNumberError("Invalid phone number");
    } else {
      setPhoneNumberError("");
    }
  }, [button.phoneNumber]);
  useEffect(() => {
    if (!button.text) {
      setTextError("Text is required");
    } else if (button.text.length > MAX_BUTTON_TEXT_LENGTH) {
      setTextError("Text is too long");
    } else {
      setTextError("");
    }
  }, [button.text]);

  const urlInputRef = useRef<HTMLInputElement>(null);

  const insertCustomFieldIntoURL = useCallback(
    (someText: string) => {
      if (!urlInputRef.current) {
        return;
      }

      const cursorPosition = urlInputRef.current.selectionStart || 0;

      handleButtonChange(
        index,
        "url",
        (button.url || "").substring(0, cursorPosition) +
          someText +
          (button.url || "").substring(cursorPosition)
      );

      urlInputRef.current.focus();
      setTimeout(() => {
        if (urlInputRef.current) {
          urlInputRef.current.selectionStart = cursorPosition + someText.length;
          urlInputRef.current.selectionEnd = cursorPosition + someText.length;
        }
      }, 0);
    },
    [button.url, handleButtonChange, index]
  );

  return (
    <Stack spacing={2} width="100%">
      <Text fontSize="lg" fontWeight="bold">
        {button.type === WhatsappButtonType.URL
          ? "URL Button"
          : button.type === WhatsappButtonType.PHONE_NUMBER
          ? "Phone Number Button"
          : "Quick Reply Button"}
      </Text>
      <HStack justifyContent="space-between" alignItems="start" width="100%">
        <Stack
          direction={isBaseSize ? "column" : "row"}
          width="100%"
          {...(isBaseSize
            ? {
                padding: "0.5rem",
                borderRadius: "1rem",
                borderStyle: "dashed",
                borderWidth: "1px",
                borderColor: colorMode === "dark" ? "gray.500" : "gray.200",
                justifyContent: "space-between",
              }
            : {})}
        >
          <FormControl isInvalid={!!textError}>
            <Input
              className="button-text-input"
              placeholder="Button text (e.g. Learn more)"
              value={button.text}
              onChange={(e) =>
                handleButtonChange(index, "text", e.target.value)
              }
              maxLength={MAX_BUTTON_TEXT_LENGTH}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>{textError}</FormErrorMessage>
          </FormControl>
          {button.type === WhatsappButtonType.URL ? (
            <FormControl isInvalid={!!urlError}>
              <InputGroup>
                <InputRightElement>
                  <AddCustomField
                    useSmallVersion={true}
                    isDisabled={isCustomFieldsDisabled}
                    customFields={customFields}
                    onCustomFieldSelect={insertCustomFieldIntoURL}
                    addNewCustomField={addNewCustomField}
                    shouldAskForExampleValues={true}
                  />
                </InputRightElement>
                <Input
                  ref={urlInputRef}
                  className="button-url-input"
                  placeholder="https://example.com"
                  value={button.url || ""}
                  onChange={(e) =>
                    handleButtonChange(index, "url", e.target.value)
                  }
                  maxLength={MAX_URL_LENGTH}
                  isDisabled={isDisabled}
                />
              </InputGroup>
              <FormErrorMessage>{urlError}</FormErrorMessage>
            </FormControl>
          ) : null}

          {button.type === WhatsappButtonType.PHONE_NUMBER ? (
            <FormControl isInvalid={!!phoneNumberError}>
              <Input
                className="button-phone-input"
                placeholder="Phone number"
                value={button.phoneNumber || ""}
                onChange={(e) =>
                  handleButtonChange(index, "phoneNumber", e.target.value)
                }
                maxLength={MAX_PHONE_LENGTH}
                isDisabled={isDisabled}
              />
              <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
            </FormControl>
          ) : null}
        </Stack>
        <IconButton
          aria-label="Remove button"
          variant="ghost"
          colorScheme="gray"
          icon={<DeleteIcon />}
          onClick={() => handleRemoveButton(index)}
          isDisabled={isDisabled}
        />
      </HStack>
    </Stack>
  );
};

export default ButtonInput;
