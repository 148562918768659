import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Icon,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as CrossIcon } from "assets/icons/Close-ds.svg";
import { ReactComponent as SendIcon } from "assets/icons/Send-arrow-ds.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-lock.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-ds.svg";
import PromptDomain, { PromptState } from "entities/domain/prompt";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { setAutoReplySuggestion } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { calculateTypingTime } from "util/methods";

interface PromptProps {
  loadedPrompt: PromptDomain | null;
  onClose: () => void;
}

const LOADING_MESSAGES = [
  "One moment, your AI assistant is thinking...",
  "One moment, your AI assistant is on it!",
  "Just a moment while your AI assistant writes your response.",
  "One second! Your AI assistant is thinking.",
  "Good things take a second - your AI assistant is working on it.",
  "Your AI assistant will have your response in just a moment.",
  "Working on it - great responses take a little thinking time!",
  "One moment, your AI assistant is working on your reply.",
  "Generating your reply, just a moment...",
  "Your AI assistant is typing… almost there!",
  "One moment, your AI assistant is thinking.",
  "One moment, your AI assistant is on it!",
  "Just a moment while we write your response.",
  "One second! We're thinking.",
  "Good things take a second - let us get that answer for you.",
  "We'll have your response in just a moment.",
  "Working on it - great responses take a little thinking time!",
  "One moment, we're working on your reply.",
  "Generating your reply, just a moment...",
  "We're on it! Just writing out your response.",
];

const LoadingPrompt = ({ message }: { message: string }) => (
  <Box display="flex" alignItems="center" justifyContent="center" width="100%">
    <Spinner mr={2} />
    <Text>{message}</Text>
  </Box>
);

const FailedPrompt = ({ onDelete }: { onDelete: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    width="100%"
  >
    <Text flexGrow={1}>
      Oops, something went wrong while working on your reply suggestion
    </Text>
    <HStack pt={1}>{onDelete}</HStack>
  </Box>
);

const CompletedPrompt = ({
  body,
  editButton,
  deleteButton,
}: {
  body: string | undefined;
  editButton: React.ReactNode;
  deleteButton: React.ReactNode;
}) => (
  <>
    <Text flexGrow={1}>{body}</Text>
    <HStack pt={1}>
      {editButton}
      {deleteButton}
    </HStack>
  </>
);

const Prompt = ({ loadedPrompt, onClose }: PromptProps): React.ReactNode => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeConversation, activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const navigate = useNavigate();
  const { search } = useLocation();
  const { closeOrOpenConversation } = useConversationsStore();

  const { track } = useAnalytics();
  const toast = useToast();

  const [draftActionPressed, setDraftActionPressed] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loadedPrompt) return;

    if (
      loadedPrompt.state === PromptState.IN_PROGRESS ||
      loadedPrompt.state === PromptState.FAILED
    ) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 30000); // 30 seconds

      return () => clearTimeout(timeoutId);
    }
  }, [loadedPrompt?.state, onClose]);

  const deleteDraft = useCallback(async () => {
    if (!activeConversationId || !loadedPrompt) {
      return;
    }

    setDraftActionPressed(true);

    if (loadedPrompt.state === PromptState.COMPLETED) {
      track("delete_draft_message", {
        conversation_id: activeConversation?.id,
      });

      try {
        await InboxService.deletePrompt(
          auth0Context,
          merchant.id,
          activeConversationId,
          loadedPrompt.id
        );
      } catch (error) {
        console.error("Error deleting draft message", error);
      }
    }

    setDraftActionPressed(false);
    onClose();
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    onClose,
  ]);

  const editDraft = useCallback(async () => {
    if (!activeConversationId || !loadedPrompt) {
      return;
    }

    track("edit_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    dispatch(setAutoReplySuggestion(loadedPrompt.body));
    try {
      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    dispatch,
  ]);

  const closeConversation = useCallback(async () => {
    if (!activeConversationId || !loadedPrompt) {
      return;
    }

    track("close_conversation", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      const updatedConversation = await closeOrOpenConversation(
        activeConversationId,
        false,
        currentAgent!.id
      );

      if (!updatedConversation) {
        toast({
          status: "error",
          title: `Failed to close conversation`,
        });
      }

      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
      navigate({
        pathname: `/${merchant.id}/inbox`,
        search: createSearchParams(search).toString(),
      });
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    currentAgent,
    closeOrOpenConversation,
    navigate,
    search,
  ]);

  const sendMessageAndCloseConversation = useCallback(async () => {
    if (!activeConversationId || !loadedPrompt) {
      return;
    }

    track("send_draft_message_and_close_conversation", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      if (loadedPrompt.body?.trim()) {
        await InboxService.sendMessage(
          auth0Context,
          {
            conversation_id: activeConversationId,
            body: loadedPrompt.body ?? "",
            reply_to_message_id: loadedPrompt.replyToMessageId,
          },
          merchant.id,
          activeConversationId
        );
        toast({
          status: "success",
          title: `You've saved at least ${calculateTypingTime(
            loadedPrompt.body ?? ""
          )}!`,
        });
      } else {
        toast({ status: "error", title: "Couldn't send prompt" });
      }

      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
      setTimeout(async () => {
        const updatedConversation = await closeOrOpenConversation(
          activeConversationId,
          false,
          currentAgent!.id
        );

        if (!updatedConversation) {
          toast({
            status: "error",
            title: `Failed to close conversation`,
          });
        }

        navigate({
          pathname: `/${merchant.id}/inbox`,
          search: createSearchParams(search).toString(),
        });
      }, 2000);
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    currentAgent,
    closeOrOpenConversation,
    navigate,
    search,
    toast,
  ]);

  const sendDraft = useCallback(async () => {
    if (
      !activeConversationId ||
      !loadedPrompt ||
      !loadedPrompt.replyToMessageId
    ) {
      return;
    }

    track("send_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      await InboxService.useDraft(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id,
        loadedPrompt.replyToMessageId
      );
      toast({
        status: "success",
        title: `You've saved at least ${calculateTypingTime(
          loadedPrompt.body ?? ""
        )}!`,
      });
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    toast,
  ]);

  const deleteDraftButton = useMemo(
    () => (
      <Tooltip label="Delete" aria-label="Delete" placement="top">
        <Button
          size="xs"
          colorScheme={colorScheme}
          variant="ghost"
          isDisabled={draftActionPressed}
          onClick={deleteDraft}
        >
          <Icon
            as={CrossIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, deleteDraft]
  );

  const editDraftButton = useMemo(
    () => (
      <Tooltip label="Edit message" aria-label="Edit message" placement="top">
        <Button
          size="xs"
          colorScheme={colorScheme}
          variant="ghost"
          isDisabled={draftActionPressed}
          onClick={editDraft}
        >
          <Icon
            as={EditIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, editDraft]
  );

  const sendDraftButton = useMemo(
    () => (
      <Tooltip label="Send message" aria-label="Send message" placement="top">
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorScheme={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.800` : `${colorScheme}.200`
          }
          py={3}
          height="auto"
          isDisabled={draftActionPressed}
          onClick={sendDraft}
        >
          <Icon
            as={SendIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, sendDraft]
  );

  const closeConversationButton = useMemo(
    () => (
      <Tooltip
        label="Close conversation"
        aria-label="Close conversation"
        placement="top"
      >
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorScheme={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.900` : `${colorScheme}.100`
          }
          py={3}
          height="auto"
          isDisabled={draftActionPressed}
          onClick={closeConversation}
        >
          <Icon
            as={CloseIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, closeConversation]
  );

  const sendMessageAndCloseConversationButton = useMemo(
    () => (
      <Tooltip
        label="Send message and close conversation"
        aria-label="Send message and close conversation"
        placement="top"
      >
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorScheme={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.700` : `${colorScheme}.300`
          }
          py={3}
          height="auto"
          isDisabled={draftActionPressed}
          onClick={sendMessageAndCloseConversation}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="2"
        >
          <Icon
            as={SendIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
          <Icon
            as={CloseIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [
      draftActionPressed,
      colorMode,
      colorScheme,
      sendMessageAndCloseConversation,
    ]
  );

  const loadingMessage = useMemo(() => {
    return LOADING_MESSAGES[
      Math.floor(Math.random() * LOADING_MESSAGES.length)
    ];
  }, [loadedPrompt?.state]);

  if (!loadedPrompt) {
    return null;
  }

  return (
    <Card
      maxWidth="lg"
      mx="auto"
      mb={4}
      backgroundColor={
        currentAgent!.stylePreferences?.chatBackground
          ? `${colorMode === "dark" ? "blackAlpha" : "whiteAlpha"}.800`
          : `${colorMode === "dark" ? "gray.600" : "white"}`
      }
    >
      {loadedPrompt.state === PromptState.IN_PROGRESS ? (
        <CardBody
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={isBaseSize ? 1 : 3}
        >
          <LoadingPrompt message={loadingMessage} />
        </CardBody>
      ) : loadedPrompt.state === PromptState.FAILED ? (
        <CardBody
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={isBaseSize ? 1 : 3}
        >
          <FailedPrompt onDelete={deleteDraftButton} />
        </CardBody>
      ) : (
        <>
          <CardBody
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            padding={isBaseSize ? 1 : 3}
          >
            <CompletedPrompt
              body={loadedPrompt.body}
              editButton={editDraftButton}
              deleteButton={deleteDraftButton}
            />
          </CardBody>
          <CardFooter padding={0}>
            <ButtonGroup
              size="sm"
              isAttached
              variant="outline"
              w="100%"
              colorScheme={colorScheme}
            >
              {loadedPrompt.hasCloseConversationAction() && (
                <>{closeConversationButton}</>
              )}
              {loadedPrompt.hasReplyAction() && <>{sendDraftButton}</>}
              {loadedPrompt.hasReplyAndCloseConversationActions() && (
                <>{sendMessageAndCloseConversationButton}</>
              )}
            </ButtonGroup>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

export default memo(Prompt);
