import { format, isThisWeek, isToday, isYesterday, parseISO } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "../services/http";
import { RequestType } from "../services/request-type";

export const maxAllowedSize = 15 * 1024 * 1024; // 15MB

export const request = async <T = any>(
  type: RequestType.GET,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    ...payload,
  });

export const requestBlob = async <T = any>(
  type: RequestType.GET,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
    ...payload,
  });

export const publicRequest = async <T = any>(
  type: RequestType = RequestType.GET,
  path: string,
  payload = {},
  headers = {}
) =>
  axios[type]<T>(
    `${path}`,
    {
      ...payload,
    },
    { headers }
  );

export const mutationRequest = async <T = any>(
  type: RequestType,
  accessToken: string,
  path: string,
  payload: object | FormData = {},
  contentType: string = "application/json;charset=UTF-8",
  headers: { [key: string]: string } = {}
) => {
  return axios[type]<T>(`${path}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      ...headers,
    },
  });
};

export const publicMutationRequest = async <T = any>(
  type: RequestType.POST,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    ...payload,
  });

export const postRequest = async <T = any>(
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[RequestType.POST]<T>(
    `${path}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

export const putRequest = async <T = any>(
  type: RequestType.PUT,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(
    `${path}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

export const patchRequest = async <T = any>(
  type: RequestType.PATCH,
  accessToken: string,
  path: string,
  payload: object | FormData = {},
  contentType: string = "application/json;charset=UTF-8"
) =>
  axios[type]<T>(`${path}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    },
  });

export const deleteRequest = async <T = any>(
  type: RequestType.DELETE,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: payload,
  });

export function dateToFromNowDaily(date: string) {
  const displayDate = parseISO(date);

  if (isToday(displayDate)) {
    return format(displayDate, "hh:mm a");
  } else if (isYesterday(displayDate)) {
    return "Yesterday";
  } else if (isThisWeek(displayDate)) {
    return format(displayDate, "eeee"); // Day of the week
  } else {
    return format(displayDate, updateFormatAccordingToCountry("dd/MM/yyyy"));
  }
}

export const getProfileImage = (image?: string | null) => image || "";

export function isMobile() {
  // credit to Timothy Huang for this regex test:
  // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
  return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const getUsernameInitials = (user: any) =>
  user.user_name.split(" ").length > 1
    ? `${user.user_name.split(" ")[0][0]}${user.user_name.split(" ")[1][0]}`
    : `${user.user_name[0]}`;

export const stripWhitespace = (text: string): string[] => {
  return text.split(" ").filter((e) => e);
};

export function useStateWithCallback<T>(
  initialState: T
): [T, (state: T, cb?: (state: T) => void) => void] {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: T) => void) | undefined>(undefined);

  const setStateCallback = useCallback(
    (stateInCallback: T, cb?: (state: T) => void) => {
      cbRef.current = cb;
      setState(stateInCallback);
    },
    []
  );

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined;
    }
  }, [state]);

  return [state, setStateCallback];
}

const MERCHANTS_TESTERS = {} as {
  [key: number]: string;
};

export const getMerchantTimeZoneByCountryCode = (
  countryCode: string
): string => {
  const timeZones: {
    [key: string]: string;
  } = {
    GB: "Europe/London",
    IE: "Europe/Dublin",
    US: "America/New_York",
    CA: "America/Toronto",
    AU: "Australia/Sydney",
    BH: "Asia/Bahrain",
  };

  if (!Object.keys(timeZones).includes(countryCode)) {
    throw Error("Country code not found");
  }

  return timeZones[countryCode];
};

export const getUTCOffsetByCountryCode = (countryCode: string): number => {
  const timezone = getMerchantTimeZoneByCountryCode(countryCode);

  return getTimezoneOffset(timezone) / 1000 / 60 / 60;
};

export const getDayNameByIndex = (index: number): string => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const day = days[index];

  return day || days[0];
};

export const isLocalEnvironment = (): boolean => {
  return window.location.host.includes("localhost");
};

export const isProductionEnvironment = (): boolean => {
  return process.env.REACT_APP_ENVIRONMENT === "production";
};

export const isFuzeyMerchant = (merchantName: string): boolean => {
  if (!merchantName) {
    return false;
  }

  return /.*Fuzey.*/i.test(merchantName);
};

export const isLondonLocksMerchant = (merchantId: number): boolean => {
  return merchantId === 127;
};

export const isFuzeyAndCoMerchant = (
  merchantName: string,
  merchantId: number
): boolean => {
  if (!merchantName && !merchantId) {
    return false;
  }

  return /.*Fuzey.*/i.test(merchantName) || !!MERCHANTS_TESTERS[merchantId];
};

export const isMobileApp = (): boolean => {
  if (localStorage.getItem("fuzeySignalPlayerId")) {
    return true;
  }

  return false;
};

export const isIOSPlatform = (): boolean => {
  return /iPhone|iPod|iPad/i.test(navigator.userAgent);
};

export const isIOSTablet = (): boolean => {
  return /iPad/i.test(navigator.userAgent);
};

export const isAndroid = (): boolean => {
  return /Android/i.test(navigator.userAgent);
};

export const isIOSMobileApp = (): boolean => {
  return isMobileApp() && isIOSPlatform();
};

export const getDisplayPrettyDate = (date: Date): string => {
  if (isToday(date)) {
    return format(date, "hh:mm a");
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isThisWeek(date)) {
    return format(date, "eeee");
  } else {
    return format(date, updateFormatAccordingToCountry("dd/MM/yyyy"));
  }
};

export function humanizeSnakeCase(input: string): string {
  return input
    .replace(/_/g, " ")
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
}

export const getReactSelectStyles = (
  colorMode: string,
  colorScheme: string,
  isBaseSize: boolean = false
) => {
  const getOptionBackgroundColor = (isSelected: boolean) => {
    if (!isSelected) {
      return {
        backgroundColor:
          colorMode === "dark" ? "var(--chakra-colors-gray-800)" : "white",
        ":hover": {
          backgroundColor:
            colorMode === "dark" ? "black" : "var(--chakra-colors-gray-50)",
        },
      };
    } else {
      return {
        backgroundColor:
          colorMode === "dark"
            ? `var(--chakra-colors-${colorScheme}-300)`
            : `var(--chakra-colors-${colorScheme}-500)`,
        ":hover": {
          backgroundColor:
            colorMode === "dark"
              ? `var(--chakra-colors-${colorScheme}-400)`
              : `var(--chakra-colors-${colorScheme}-400)`,
        },
      };
    }
  };

  return {
    placeholder: (styles: any) => ({
      ...styles,
      color:
        colorMode === "dark"
          ? "var(--chakra-colors-gray-200)"
          : "var(--chakra-colors-gray-500)",
    }),
    container: (styles: any) => ({
      ...styles,
      width: isBaseSize ? "100%" : "auto",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      borderRadius: "20px",
      borderWidth: 0,
      ...(state.selectProps["aria-invalid"]
        ? {
            border: `1px solid var(--chakra-colors-red-${
              colorMode === "dark" ? 300 : 500
            })`,
          }
        : {}),
      ":focus": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: `var(--chakra-colors-${colorScheme}-${
          colorMode === "dark" ? 200 : 500
        })`,
      },
      backgroundColor:
        colorMode === "dark"
          ? "var(--chakra-colors-gray-900)"
          : "var(--chakra-colors-gray-50)",
      color: colorMode === "dark" ? "white" : "black",
      minHeight: "2.5rem",
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 9999,
      borderRadius: "1rem",
      overflow: "hidden",
    }),
    menuPortal: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
    indicatorsContainer: (baseStyles: any) => ({
      ...baseStyles,
      "> div:first-child": {
        padding: 0,
      },
    }),
    indicatorSeparator: (baseStyles: any) => ({
      ...baseStyles,
      width: 0,
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: "1rem",
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor:
        colorMode === "dark" ? "var(--chakra-colors-gray-800)" : "white",
      color: colorMode === "dark" ? "white" : "black",
    }),
    option: (baseStyles: any, { isSelected }: any) => ({
      ...baseStyles,
      ...(getOptionBackgroundColor(isSelected) as any),
    }),
    multiValue: (baseStyles: any, { data }: any) => {
      const labelColor =
        data.color ||
        (colorMode === "dark"
          ? `var(--chakra-colors-${colorScheme}-300)`
          : `var(--chakra-colors-${colorScheme}-500)`);

      return {
        ...baseStyles,
        backgroundColor: "inherit",
        border: `1px solid ${labelColor}`,
        borderRadius: "0.5rem",
        color: colorMode === "dark" ? "white" : "black",
      };
    },
    multiValueRemove: (baseStyles: any) => ({
      ...baseStyles,
      ":hover": {
        backgroundColor: "transparent",
      },
    }),
    dropdownIndicator: (baseStyles: any) => ({
      ...baseStyles,
      backgroundColor: "transparent",
    }),
  };
};

export function getSelectStylesForQueryBuilder(
  _colorScheme: string,
  colorMode: string
) {
  return {
    control: (styles: any, { isDisabled }: any) => ({
      ...styles,
      borderWidth: 0,
      backgroundColor: "transparent",
      color: colorMode === "dark" ? "white" : "black",
      boxShadow: "none",
      borderRadius: 0,
      borderBottomWidth: 0,
      cursor: isDisabled ? "default" : "pointer",
      minHeight: "auto",
    }),
    input: (styles: any) => ({
      ...styles,
      margin: 0,
      padding: 0,
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "inherit",
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 99,
      borderRadius: "0.5rem",
      overflow: "hidden",
      width: "auto",
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    indicatorsContainer: (baseStyles: any) => ({
      ...baseStyles,
      "> div": {
        padding: 0,
      },
    }),
    indicatorSeparator: (baseStyles: any) => ({
      ...baseStyles,
      width: 0,
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: "0.5rem",
      width: "auto",
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor:
        colorMode === "dark" ? "var(--chakra-colors-gray-800)" : "white",
      color: colorMode === "dark" ? "white" : "black",
    }),
    dropdownIndicator: (baseStyles: any, { isDisabled }: any) => ({
      ...baseStyles,
      backgroundColor: "transparent",
      display: isDisabled ? "none" : "flex",
    }),
  };
}

export function calculateTypingTime(text: string): string {
  const words = text.split(" ").length;
  const averageTypingSpeedWordsPerSecond = 0.67; // average typing speed - 40 words per minute
  let seconds = Math.round(words / averageTypingSpeedWordsPerSecond);

  if (seconds < 60) {
    return `${seconds} seconds`;
  } else {
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${minutes} minutes ${seconds} seconds`;
  }
}

function findPositions(dateFormat: string): {
  mmPos: number[];
  ddPos: number[];
} | null {
  const mmStart = dateFormat.toUpperCase().indexOf("MM");
  const mmEnd = mmStart + 2;
  const ddStart = dateFormat.toUpperCase().indexOf("DD");
  const ddEnd = ddStart + 2;

  if (mmStart === -1 || ddStart === -1) {
    return null; // 'MM' or 'DD' not found in the string
  }

  return {
    mmPos: [mmStart, mmEnd],
    ddPos: [ddStart, ddEnd],
  };
}

function americanizeDateFormat(dateFormat: string): string | null {
  if (!dateFormat) {
    return null;
  }

  const needsAmerica = dateFormat.includes("dd") && dateFormat.includes("MM");

  if (!needsAmerica) {
    return null;
  }

  const positionsOfMonthAndDay = findPositions(dateFormat);

  if (!positionsOfMonthAndDay) {
    return null;
  }

  const { mmPos, ddPos } = positionsOfMonthAndDay;

  const mm = dateFormat.substring(mmPos[0], mmPos[1]);
  const dd = dateFormat.substring(ddPos[0], ddPos[1]);

  const newFormat = dateFormat.replace(mm, "dd").replace(dd, "MM");

  return newFormat;
}

export function updateFormatAccordingToCountry(dateFormat: string): string {
  if (!dateFormat) {
    return dateFormat;
  }

  const country = localStorage.getItem("fuzey:merchant:country");

  if (!country) {
    return dateFormat;
  }

  if (country === "USA") {
    const newFormat = americanizeDateFormat(dateFormat);

    if (newFormat) {
      return newFormat;
    }
  }

  return dateFormat;
}

export const containsPrefix = (text: string, prefix: string) =>
  text.substring(0, prefix.length) === prefix;

export const containsSuffix = (text: string, suffix: string) =>
  text.substring(text.length - suffix.length) === suffix;

export const convertSecondsToHoursMinutesSeconds = (
  seconds: number
): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours > 0 ? `${hours}:` : ""}${
    minutes > 9 ? minutes : `0${minutes}`
  }:${remainingSeconds > 9 ? remainingSeconds : `0${remainingSeconds}`}`;
};

function countNonNullAttributes<T>(
  obj: T,
  numericAttributes: (keyof T)[]
): number {
  let count = 0;
  for (const key in obj) {
    if (!numericAttributes.includes(key)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (obj[key] !== null) {
      count++;
    }
  }
  return count;
}

function compareNumericAttributes<T>(
  a: T,
  b: T,
  attributes: (keyof T)[],
  sorting: "asc" | "desc"
): number {
  for (const attr of attributes) {
    if (typeof a[attr] === "number" && typeof b[attr] === "number") {
      if (a[attr] !== b[attr]) {
        if (sorting === "asc") {
          return (
            (a[attr] as unknown as number) - (b[attr] as unknown as number)
          );
        } else {
          return (
            (b[attr] as unknown as number) - (a[attr] as unknown as number)
          );
        }
      }
    }
  }
  return 0;
}

export function sortObjects<T>(
  objects: T[],
  numericAttributes: (keyof T)[],
  sorting: "asc" | "desc" = "desc"
): T[] {
  return [...objects].sort((a, b) => {
    const nonNullComparison =
      countNonNullAttributes(b, numericAttributes) -
      countNonNullAttributes(a, numericAttributes);
    if (nonNullComparison !== 0) {
      return nonNullComparison;
    }
    return compareNumericAttributes(a, b, numericAttributes, sorting);
  });
}

export const removePrefixAndSuffix = (
  text: string,
  prefix: string,
  suffix: string
) => {
  if (prefix && containsPrefix(text, prefix)) {
    text = text.substring(prefix.length + 1);
  }

  if (suffix && containsSuffix(text, suffix)) {
    text = text.substring(0, text.length - suffix.length);
  }

  return text;
};

export function humanizeCamelCase(input: string): string {
  // First, replace capital letters with spaces + the capital letter.
  let result = input.replace(/([A-Z])/g, " $1");

  // Trim any leading spaces and then capitalize the first letter.
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
}

export function flattenObject(potentiallyNestedObject: {
  [key: string]: any;
}): { [key: string]: any } {
  const flattened: { [key: string]: any } = {};

  Object.keys(potentiallyNestedObject).forEach((key) => {
    const value = potentiallyNestedObject[key];

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
}

export const getChannelsFromConversations = (
  conversations: ConversationDomain[],
  selectedConversationIds: number[]
): string[] => {
  const selectedConversations = conversations.filter((c) =>
    selectedConversationIds.includes(c.id)
  );

  const selectedConversationsChannels = selectedConversations.map(
    (c) => c.customerChannelId
  );

  return selectedConversationsChannels;
};

export const replaceCustomFields = (
  text: string | null,
  customFields: { [key: string]: string }
): string | null => {
  if (!text) {
    return text;
  }

  let newText = `${text}`;

  Object.keys(customFields).forEach((field) => {
    newText = newText.replaceAll(`{${field}}`, customFields[field]);
  });

  return newText;
};

export function getUniqueListBy<T>(arr: T[], key: keyof T): T[] {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function isMacintosh() {
  return navigator.userAgent.indexOf("Mac") > -1;
}

export function isWindows() {
  return navigator.userAgent.indexOf("Win") > -1;
}
